import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
// import SafeBox from "../views/SafeBox.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/security',
    name: 'Security',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Security.vue'),
  },
  {
    path: '/academy',
    name: 'Academy',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Academy.vue'),
  },
  {
    path: '/keypal',
    name: 'KeyPal',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/KeyPal.vue'),
  },
  {
    path: '/keypalplus',
    name: 'KeyPalPlus',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/KeyPalPlus.vue'),
  },
  {
    path: '/blockchain',
    name: 'Blockchain',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Blockchain.vue'),
  },
  {
    path: '/firmwareupgrade',
    name: 'FirmwareUpgrade',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/FirmwareUpgrade.vue'),
  },
  {
    path: '/safebox',
    name: 'SafeBox',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/SafeBox.vue'),
  },
  // {
  //   path: '/keypal-card',
  //   name: 'KeypalCard',
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ '../views/KeypalCard.vue'),
  // },
  // {
  //   path: '/keypal-card-instructions',
  //   name: 'KeyPalCardInstructions',
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ '../views/KeyPalCardInstructions.vue'),
  // },
  // {
  //   path: '/safebox',
  //   name: 'SafeBox',
  //   component: SafeBox
  // },
];

const router = new VueRouter({
  routes,
});

// 获取原型对象push函数
const originalPush = VueRouter.prototype.push;

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace;

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

export default router;
