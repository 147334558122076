export default {
  title: 'List of blockchains supported by KeyPal (1000+)',
  getKeypal: 'Get your KeyPal',
  comingSoon: 'Coming soon',
  list: {
    title: 'Blockchain',
    detail: 'Details',
    urlName: 'Link',
  },
  // 英文状态下用不到
  // keypal: {
  //   youzan: '有赞',
  //   tb: '淘宝',
  //   youzanDesc: '使用支付宝/微信扫码进行购买',
  //   tbDesc: '使用支付宝/淘宝扫码进行购买',
  //   urlName: '访问网页版',
  //   buyWay: '请选择支付购买方式',
  //   cancel: '取消',
  // },
}
