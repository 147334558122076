export default {
  buy_desc_1: 'Support 1000+ blockchains',
  buy_desc_2: 'Including BTC, ETH, TRON, BSC, and EVM public chains',
  buy_desc_3: 'One device to manage all crypto assets',

  features: 'Features',
  features_title_1: 'MCU+SE security chip',
  features_desc_1: 'Military grade chips to guarantee the security',
  features_title_2: '2.0-inch LCD screen',
  features_desc_2: 'More details of TX, you sign what you see',
  features_title_3: 'Bluetooth connection',
  features_desc_3: 'Desensitized communication',
  features_title_4: 'Multi-layer protections',
  features_desc_4: 'Offline, PIN code, identified connection',
  color: 'Color',
  free_shipping: 'Free shipping',

  break_down_desc_1: 'LEFT Button',
  break_down_desc_2: 'Move left in the display or back to previous page',
  break_down_desc_3: 'OK Button',
  break_down_desc_4:
    'Confirm actions or enter the menu page, long press to turn on or off',
  break_down_desc_5: 'UP Button',
  break_down_desc_6: 'Move up in the display',
  break_down_desc_7: 'RIGHT Button',
  break_down_desc_8: 'Move right in the display or confirm actions',
  break_down_desc_9: 'DOWN Button',
  break_down_desc_10: 'Move down in the display',

  display_title: 'Display',
  display_desc: '2-inch, LCD display',
  battery_title: 'Battery',
  battery_desc: '100mAh',
  materials_title: 'Materials',
  materials_desc: 'Aluminum alloy',
  dual_chip_title: 'Dual-chip',
  dual_chip_desc: 'MCU + SE security chips',

  easy_and_safe_title: 'Easy and Safe',
  easy_and_safe_desc:
    'Easy to connect, easy to use and safe to protect crypto assets',
  easy_and_safe_list_1: 'Dual-chip',
  easy_and_safe_list_2: 'MCU + SE security chip',
  easy_and_safe_list_3: 'Easy connection',
  easy_and_safe_list_4: 'Bluetooth and Type-C',
  easy_and_safe_list_5: '2-inch display',
  easy_and_safe_list_6: 'Sign what you see',

  product_specifications: 'Product Specifications',
  detail_center: 'USB Type-C',
  detail_left_1: 'Aluminum alloy shell Protect the wallet from damage',
  detail_left_2: 'LEFT Button',
  detail_left_3: 'DOWN Button',
  detail_right_1: '2-inch display To show the related transaction',
  detail_right_2: 'UP Button',
  detail_right_3: 'RIGHT Button',
  detail_right_4: 'OK Button',

  keypal_card_1: 'Size',
  keypal_card_label_1: '91mm*55mm*6.7mm',
  keypal_card_2: 'Power and Battery',
  keypal_card_label_2: '100mAh Li-ion battery',
  keypal_card_3: 'Connection',
  keypal_card_label_3: 'Bluetooth & Type-C',
  keypal_card_4: 'Materials',
  keypal_card_label_4: 'Aluminum alloy, plastic',
  keypal_card_5: 'Weight',
  keypal_card_label_5: '53g',
  keypal_card_6: 'Display',
  keypal_card_label_6: '2-inch screen LCD display',
  keypal_card_7: 'Chip',
  keypal_card_label_7: 'MCU+SE',
  keypal_card_8: 'Certifications',
  keypal_card_label_8: 'DOE CEC BEC CE FCC RoHS',

  package: 'Package',
  package_line_1: '1 Hardware Wallet',
  package_line_2: '1 USB Type-C Cable',
  package_line_3: '2 Recovery Sheets',
  package_line_4: '1 Sticker',

  keypal_plus: {
    larger_battery: '200% larger battery capacity',

    keypal_card_1: 'Size',
    keypal_card_label_1: '91mm*55mm*9.0mm',
    keypal_card_2: 'Power and Battery',
    keypal_card_label_2: '300mAh Li-ion battery',
    keypal_card_3: 'Connection',
    keypal_card_label_3: 'Bluetooth & Type-C',
    keypal_card_4: 'Materials',
    keypal_card_label_4: 'Aluminum alloy, plastic',
    keypal_card_5: 'Weight',
    keypal_card_label_5: '63g',
    keypal_card_6: 'Display',
    keypal_card_label_6: '2-inch screen LCD display',
    keypal_card_7: 'Chip',
    keypal_card_label_7: 'MCU+SE',
    keypal_card_8: 'Certifications',
    keypal_card_label_8: 'DOE CEC BEC CE FCC RoHS',

    color: 'Color',
    color_desc: 'Black, Silver, Golden',
    battery: 'Battery',
    battery_desc: '300 mAh',
    left: 'LEFT',
    down: 'DOWN',
    right: 'RIGHT',
    up: 'UP',
    ok: 'OK',
    center: 'USB Type-C',
    left_1: 'LEFT Button',
    left_2: 'Move left in the display or back to previous page',
    left_3: 'DOWN Button',
    left_4: 'Move down in the display',
    right_1: 'UP Button',
    right_2: 'Move up in the display',
    right_3: 'RIGHT Button',
    right_4: 'Move right in the display or confirm actions',
    right_5: 'OK Button',
    right_6:
      'Confirm actions or enter the menu page, long press to turn on or off',
    mobile_center: 'Type-C',
    mobile_center_2: 'For charging and firmware upgrading',
  },
};
