import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isDark: false,
    framesData: [],
  },
  mutations: {
    setDark(state, payload) {
      state.isDark = payload;
    },
    setFramesData(state, data) {
      state.framesData = data;
    },
  },
  actions: {},
  getters: {
    isDark(state) {
      return state.isDark;
    },
    framesData(state) {
      return state.framesData;
    },
  },
  modules: {},
});
