export default {
  text_1_1_1: '備份，',
  text_1_1_2: '從未如此簡單且安全',
  text_1_2_1: '更多選擇，',
  text_1_2_2: '滿足你對私密的需求',
  text_2_1_1: '耐用，易攜',
  text_2_1_2: '不易彎折',
  text_2_2: '由CC EAL6+ 晶片保護安全',
  text_2_3: '隨身攜帶',
  text_2_4: '抗彎折、防磁、防靜電',
  text_2_5: '可支援五十萬次刷卡使用',
  text_3_1:
    '由TokenPocket提供支援，您可以在使用KeyPal Card時享受完美的使用者體驗，',
  text_4_1: 'PIN碼保護卡片安全',
  text_4_2: '卡片連續十次輸入錯誤的PIN碼後，會自動抹除數據',
  text_4_3_1: '不只是備份卡，',
  text_4_3_2: '也可以是簽名卡',
  text_4_4: '卡片的多元性使用，不僅可用於備份，更可用於參與交易簽名的一個環節',
  text_4_5: 'NFC感應，一觸備份，簡單又安全。',
  text_4_6: '透過電磁場雙向加密通信，快速回應，實現快速備份，告別繁瑣。',
  text_5_1: 'Time to get started with your',
  text_6_1: '錢包',
  text_6_2:
    '完美融合，讓您的皮夾體驗卓越無比，讓每一張KeyPal Card都與其無縫銜接！',
  text_6_3: '備份',
  text_6_4: '讓您的錢包備份簡單而安全，不再因錯誤的備份而失去資產',
  text_6_5: '簽名',
  text_6_6: '讓您用更多方式使用錢包，用你的KeyPal Card執行每一次簽名',
};
