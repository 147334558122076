export default {
  title: 'KeyPal支持的區塊鏈列表（1000+）',
  getKeypal: '擁有 KeyPal',
  comingSoon: '支持中',
  list: {
    title: 'Blockchain',
    detail: '詳情',
    urlName: '鏈接',
  },
  keypal: {
    youzan: '有贊',
    tb: '淘寶',
    youzanDesc: '使用支付寶/微信掃碼進行購買',
    tbDesc: '使用支付寶/淘寶掃碼進行購買',
    urlName: '訪問網頁版',
    buyWay: '請選擇支付購買方式',
    cancel: '取消',
  },
}
