export default {
  title_1_1: '助記詞',
  title_1_2: '密盒',
  desc_1_2: '堅固，安全，易用',

  title_2_1: '全新工藝',
  title_2_2: '更牢固、更安全',
  desc_2_1:
    'Adopt full laser production process, thicken the thickness of the panel and enhance the protection ability',
  desc_2_2: 'More screw locks, more safety locks, and tighter fit',
  desc_2_3: 'Support 2 sets of 12-bit mnemonic at the same tim',
  desc_2_4:
    '採用全激光製作工藝，加厚面板厚度，增強防護能力 更多螺絲鎖，更多安全鎖，讓面板間更緊密貼合 同時支持2套12位助記詞 貼心讓你安全放心',

  title_3_1_1: '驚人的',
  title_3_1_2: '三防效果',
  desc_3_2: '防水耐腐蝕',
  desc_3_3_1: '採用精製304不銹鋼材料',
  desc_3_3_2: '不怕水，耐腐蝕',
  desc_3_3_3: '儲存環境友好，無需擔心"風吹日曬"',
  desc_3_3_4: '讓您的秘鑰經久不變',
  desc_3_4: '防火',
  desc_3_5_1: '超高的熔點溫度',
  desc_3_5_2: '具有優良的防火性能、耐火性能',
  desc_3_5_3: '在每一場不受歡迎的火焰中',
  desc_3_5_4: '堅固保護，不怕"紅爐火"',
  desc_3_6: '防碰撞',
  desc_3_7_1: '採用高強度製作工藝',
  desc_3_7_2: '面板更厚，貼合更緊密',
  desc_3_7_3: '在每一個意料之外的碰撞中',
  desc_3_7_4: '牢牢保護內部的每一個字母',

  title_4_1: '字母板',
  desc_4_1_1: '多功能字母板',
  desc_4_1_2: '讓記錄更便捷，更高效',
  desc_4_2_1: '全新設計，滿足更多需求，6片字母板可拆卸出351片字符',
  desc_4_2_2: '支持字母大小寫，增加數字和符號字符',
  desc_4_2_3: '雙面設計，可展示702個字符',
  desc_4_3: '351片字符',
  desc_4_4: '支持大小寫',
  desc_4_5: '支持數字和符號',
  desc_4_6: '支持雙面功能',

  title_5_1: '操作示意圖',
  desc_5_1: '助記詞鋼板使用步驟，簡單易學',
  desc_5_2: '步驟 1',
  desc_5_3: '旋轉打開密盒，逆時針打開',
  desc_5_4: '步驟 2',
  desc_5_5: '用螺絲刀分別擰開5個螺絲',
  desc_5_6: '步驟 3',
  desc_5_7: '鬆開螺絲後向上旋轉打開密盒',
  desc_5_8: '步驟 4',
  desc_5_9: '將助記詞對應字母分別放進字母槽',
  desc_5_10: '步驟 5',
  desc_5_11: '放置好助記詞對應字母后，核驗無誤後合上密盒，並擰緊5個螺絲',

  title_6_1: '產品參數 / 包裝',
  desc_6_1: '助記詞密盒',
  desc_6_3: '字母板',
  desc_6_5: 'EVA 包',
  desc_6_7: '說明書/螺絲/螺絲刀',

  desc_7_1: '密盒規格參數',
  desc_7_2: '尺寸',
  desc_7_3: '大小',
  desc_7_4: '字母版規格',
  desc_7_5: '3片一套 總共351塊字母塊',
};
