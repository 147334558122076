<template>
  <div
    class="header"
    :class="{
      security: security,
      isKeyPal: isKeyPal,
      'keypal-detail': isKeyPalDetail,
      'safe-box': isSafeBox,
      'keypal-card': isKeyPalCard,
    }"
  >
    <div class="content d-flex ai-center jc-between">
      <img class="logo" :src="logoImg" @click="goHome" />
      <div
        v-if="$route.path !== '/keypal-card-instructions'"
        class="nav-wrap d-flex ai-center jc-between flex-1"
        :class="{ 'safe-box': isSafeBox }"
      >
        <div class="nav-item-wrap d-flex ai-center" v-if="!isSafeBox">
          <div
            class="nav-item"
            v-for="(navItem, navIndex) in navList"
            :key="navIndex"
            @click="onNavItem(navItem)"
          >
            <div class="text title">
              <div class="title" :class="{ security: security }">
                {{ navItem.title }}
              </div>
            </div>
            <img class="arrow" v-if="navItem.child" :src="arrowImg" />
            <div
              class="nav-child-wrap d-flex"
              v-if="navItem.child"
              @mouseleave="leaveNavItem"
            >
              <div class="left-wrap" :class="{ active: isOffspring }">
                <a
                  class="nav-child-item d-flex ai-center"
                  :class="{ active: isOffspring && offspringIndex === index }"
                  v-for="(navChild, index) in navItem.child"
                  :key="index"
                  :href="navChild.url ? navChild.url : 'javascript:void(0)'"
                  :target="navChild.local ? '_self' : '_blank'"
                  @mouseenter="enterNavChild(navChild, index)"
                  @click="onNavChild(navChild)"
                >
                  <!-- :target="navChild.url ? '_blank' : '_self'" -->
                  <!-- @mouseleave="leaveNavItem" -->
                  <img class="icon" :src="navChild.icon" />
                  <div class="label-wrap flex-1 d-flex ai-center jc-between">
                    <span class="text">{{ navChild.label }}</span>
                    <img
                      class="arrow-right"
                      v-if="navChild.offspring"
                      src="../../assets/images/home/arrow-right.png"
                    />
                  </div>
                </a>
              </div>
              <div
                class="offspring-wrap"
                v-show="navItem.offspring && isOffspring"
              >
                <div class="white-bg">
                  <a
                    class="offspring-item d-flex ai-center"
                    v-for="offspring in navItem.offspring"
                    :key="offspring.label"
                    :href="offspring.url ? offspring.url : 'javascript:void(0)'"
                    :target="offspring.local ? '_self' : '_blank'"
                    @click="onNavChild(offspring)"
                  >
                    <span class="text">{{ offspring.label }}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 语言栏 -->
        <div class="nav-item-wrap">
          <div class="nav-item nav-item-lang" :class="{ security: security }">
            <div class="text title" :class="{ security: security }">
              {{ langLabel }}
            </div>
            <div class="nav-child-wrap-bg">
              <div class="nav-child-wrap" v-if="langData.child">
                <div
                  class="nav-child-item d-flex ai-center"
                  v-for="(langItem, index) in langData.child"
                  :key="index"
                  @click="changeLang(langItem.lang)"
                >
                  <span class="text">{{ langItem.label }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="keypal-card-buy" @click="goBuyKeyPalCard">{{ $t('home.preSale') }}</div>
      <img
        class="menu"
        :src="menuImg"
        :class="{ active: isMenuMobile }"
        @click="onMenu"
      />
    </div>
    <MenuMobile v-if="isMenuMobile" @close="closeMenuMobile" />
  </div>
</template>

<script>
import MenuMobile from '../common/MenuMobile.vue';
import buyCardLink from "../../mixins/buyCardLink";
export default {
  props: {
    security: {
      type: Boolean,
    },
    isKeyPalCard: {
      type: Boolean,
    },
    isKeyPal: {
      type: Boolean,
    },
    isSafeBox: {
      type: Boolean,
    },
    isKeyPalDetail: {
      type: Boolean,
    },
  },
  components: { MenuMobile },
  data() {
    return {
      isMenuMobile: false,
      offspringIndex: undefined,
      isOffspring: false,
    };
  },
  mixins: [buyCardLink],
  computed: {
    menuImg() {
      if (this.security) {
        return require('../../assets/images/security/more-w.png');
      } else {
        return require('../../assets/images/home/menu-more.png');
      }
    },
    logoImg() {
      if (this.security) {
        return require('../../assets/images/security/logo-w.png');
      } else {
        return require('../../assets/images/home/logo.png');
      }
    },
    arrowImg() {
      if (this.security) {
        return require('../../assets/images/home/arrow-w.png');
      } else {
        return require('../../assets/images/home/arrow.png');
      }
    },
    navList() {
      return [
        {
          title: this.$t('common.product'),
          child: [
            {
              label: this.$t('navigation.product.label_1'),
              icon: require('../../assets/images/navigation/product/icon-1.png'),
              offspring: true,
              local: true,
            },
            {
              label: this.$t('navigation.product.label_5'),
              icon: require('../../assets/images/navigation/product/icon-5.png'),
              local: true,
              url: `https://card.keypal.pro/`,
              // routePath: '/keypal-card',
            },
            {
              label: this.$t('navigation.product.label_2'),
              icon: require('../../assets/images/navigation/product/icon-2.png'),
              // url: 'https://keypalwallet.mystrikingly.com/store/products/keypal-mnemonic-secret-box',
              routePath: '/safebox',
              local: true,
            },
            {
              label: this.$t('navigation.product.label_3'),
              icon: require('../../assets/images/navigation/product/icon-3.png'),
              url:
                this.$i18n.locale === 'zh'
                  ? 'https://shop95838799.m.youzan.com/wscgoods/detail/366w49k8dlkyf'
                  : 'https://www.keypal.store/products/keypal-leather-case',
            },
            {
              label: this.$t('navigation.product.label_4'),
              icon: require('../../assets/images/navigation/product/icon-4.png'),
              url:
                this.$i18n.locale === 'zh'
                  ? 'https://shop95838799.m.youzan.com/v2/showcase/homepage?alias=sptfzUCg0j'
                  : 'https://www.keypal.store/',
            },
          ],
          offspring: [
            {
              label: 'KeyPal Plus',
              routePath: '/keypalplus',
              local: true
            },
            // {
            //   label: 'KeyPal',
            //   routePath: '/keypal',
              // url:
              //   this.$i18n.locale === 'zh'
              //     ? 'https://shop95838799.m.youzan.com/wscgoods/detail/2od5z9zzdfzkn'
              //     : 'https://keypalwallet.mystrikingly.com/store/products/keypal-hardware-wallet',
            // },
          ],
        },
        {
          title: this.$t('navigation.upgrade.label_1'),
          routePath: '/firmwareupgrade',
        },
        {
          title: this.$t('common.learn'),
          child: [
            {
              label: this.$t('navigation.learn.label_1'),
              // desc: this.$t('navigation.learn.desc_1'),
              icon: require('../../assets/images/navigation/learn/icon-1.png'),
              routePath: '/security',
              local: true,
            },
            // {
            //   label: this.$t('navigation.learn.label_2'),
            //   desc: this.$t('navigation.learn.desc_2'),
            //   icon: require('../../assets/images/navigation/learn/icon-3.png'),
            //   url: 'https://tokenpocket.pro/',
            // },
            {
              label: this.$t('navigation.learn.label_3'),
              icon: require('../../assets/images/navigation/learn/icon-3.png'),
              offspring: true,
              local: true,
            },
          ],
          offspring: [
            {
              label: this.$t('navigation.learn.label_3_1'),
              routePath: '/academy',
              local: true,
            },
            {
              label: this.$t('navigation.learn.label_3_2'),
              // routePath: '/keypal-card-instructions',
              local: true,
              url: `https://card.keypal.pro/tutorials`,
            },
          ],
        },
        {
          title: this.$t('common.support'),
          child: [
            {
              label: this.$t('navigation.support.label_1'),
              icon: require('../../assets/images/navigation/support/icon-1.png'),
              url:
                this.$i18n.locale === 'en'
                  ? 'https://keypal.gitbook.io/en/keypal-tutorial'
                  : 'https://keypal.gitbook.io/zh-cn/keypal-tutorial',
            },
            {
              label: this.$t('navigation.support.label_2'),
              icon: require('../../assets/images/navigation/support/icon-2.png'),
              url:
                this.$i18n.locale === 'en'
                  ? 'https://keypal.gitbook.io/en/buy-faq'
                  : 'https://keypal.gitbook.io/zh-cn/buy-faq',
            },
            {
              label: this.$t('navigation.support.label_3'),
              icon: require('../../assets/images/navigation/support/icon-3.png'),
              url: 'https://fans.tokenpocket.pro/',
            },
          ],
        },
      ];
    },
    langData() {
      return {
        title: this.$t('common.language'),
        lang: true,
        child: [
          { label: 'English', lang: 'en' },
          { label: '中文(简体)', lang: 'zh' },
          { label: '中文(繁体)', lang: 'zh-tw' },
        ],
      };
    },
    langLabel() {
      let lang = this.$i18n.locale;
      switch (lang) {
        case 'zh':
          return '中文(简体)';
        case 'zh-tw':
          return '中文(繁体)';
        case 'en':
          return 'English';
        default:
          return '中文(简体)';
      }
    },
  },
  mounted() {
    document.body.style.overflow = 'auto';
  },
  methods: {
    // 二级标题跳转
    onNavChild(navChild) {
      if (navChild.routePath) {
        this.$router.push({ path: navChild.routePath });
      }
    },
    enterNavChild(navChild, index) {
      this.offspringIndex = index;
      if (navChild.offspring) {
        this.isOffspring = true;
      } else {
        this.isOffspring = false;
      }
    },
    leaveNavItem() {
      this.isOffspring = false;
      // this.offspringIndex = undefined
    },
    onMenu() {
      this.isMenuMobile = !this.isMenuMobile;
      if (this.isMenuMobile) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    },
    closeMenuMobile() {
      this.isMenuMobile = false;
      document.body.style.overflow = 'auto';
    },
    onNavItem(item) {
      if (item.routePath) {
        this.$router.push({ path: item.routePath });
      }
    },
    goHome() {
      if (this.$route.path !== '/') {
        this.$router.push({ path: '/' });
      }
    },
    changeLang(lang) {
      this.$i18n.locale = lang;
      document.title = this.$t('common.documentTitle');
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  background: #fbfdff;
  padding: 0.15rem 0;
  &.safe-box {
    position: absolute;
    width: -webkit-fill-available;
    width: -moz-available; //火狐浏览器
    z-index: 8888;
    background: #fff;
  }
  &.security {
    background: #101010;
  }
  &.keypal-card {
    background: #000000;
  }
  &.isKeyPal {
    background: #fff;
  }
  .logo {
    width: 1.28rem;
    cursor: pointer;
  }
  .nav-wrap {
    &.safe-box {
      justify-content: flex-end;
    }
    .nav-item-wrap {
      margin-left: 0.6rem;
      .nav-item {
        height: 0.5rem;
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:nth-child(n + 2) {
          margin-left: 0.4rem;
        }
        .title {
          font-size: 0.16rem;
          font-family: Inter-Regular;
          color: #101010;
          font-weight: 400;
          &.security {
            color: #fff;
          }
        }
        .arrow {
          margin-left: 0.04rem;
          width: 0.16rem;
          transform-origin: center center;
          transform: rotate(0deg);
        }
        .nav-child-wrap {
          display: none;
          position: absolute;
          z-index: 999;
          top: 0.5rem;
          left: -0.1rem;
          // padding: 0.12rem;
          background: #ffffff;
          border-radius: 0.12rem;
          box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.2);
          .left-wrap {
            padding: 0.12rem;
            &.active {
              padding: 0.12rem 0 0.12rem 0.12rem;
            }
          }
          .nav-child-item {
            padding: 0.07rem 0.2rem;
            &.active {
              background: #f5f5f5;
              border-radius: 0.12rem;
            }
            &:nth-child(n + 2) {
              margin-top: 0.12rem;
            }
            .icon {
              width: 0.24rem;
            }
            .label-wrap {
              margin-left: 0.12rem;
              .arrow-right {
                width: 0.12rem;
                transform: translateX(0.14rem);
              }
              span {
                white-space: nowrap;
                font-size: 0.13rem;
                font-family: Inter-Regular;
                color: #101010;
              }
            }
          }
          .offspring-wrap {
            // display: none;
            // position: absolute;
            top: 0;
            right: -1.42rem;
            background: #ffffff;
            border-radius: 0 0.12rem 0.12rem 0;
            height: inherit;
            .white-bg {
              height: 100%;
              padding: 0.12rem;
              margin-left: 0.12rem;
              border-radius: 0.12rem;
              background: #fbfbfb;
              .offspring-item {
                padding: 0.07rem 0.2rem;
                &:nth-child(n + 2) {
                  margin-top: 0.12rem;
                }
              }
              .offspring-item:hover {
                background: #f5f5f5;
                border-radius: 0.12rem;
              }
              span {
                white-space: nowrap;
              }
            }
          }
          .nav-child-item:hover {
            background: #f5f5f5;
            border-radius: 0.12rem;
            // .offspring-wrap {
            //   display: block;
            // }
          }
        }
      }
      .nav-item:hover {
        .nav-child-wrap {
          display: flex;
        }
        .arrow {
          transform: rotate(-180deg);
          transition: transform 0.3s;
        }
      }
      .nav-item-lang {
        min-width: 1rem;
        height: auto;
        border: 0.01rem solid #101010;
        border-radius: 0.5rem;
        &.security {
          border: 0.01rem solid #fff;
        }
        .title {
          margin: 0 auto;
          padding: 0.07rem 0.2rem;
          font-size: 0.12rem;
          line-height: 0.17rem;
          &.security {
            color: #fff;
          }
        }
        .nav-child-wrap-bg {
          display: none;
          padding-top: 0.1rem;
          position: absolute;
          z-index: 999;
          left: 50%;
          transform: translateX(-50%);
          top: 0.29rem;
          .nav-child-wrap {
            position: static;
            padding: 0.12rem;
            flex-direction: column;
            .nav-child-item {
              span {
                margin: 0;
                white-space: nowrap;
              }
            }
          }
        }
      }
      .nav-item-lang:hover {
        .nav-child-wrap-bg {
          display: block;
        }
      }
    }
  }
  .keypal-card-buy {
    background: #2980fe;
    border-radius: 0.04rem;
    padding: 0.13rem 0.31rem;
    margin: 0.02rem 0;
    font-size: 0.14rem;
    font-family: PingFang SC, PingFang SC-500;
    font-weight: 500;
    color: #ffffff;
    cursor: pointer;
  }
  .menu {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .header {
    padding: 0.37rem 0;
    &.keypal-detail {
      background: #f6f6f6;
    }
    .content {
      justify-content: space-between;
    }
    .logo {
      width: 2.31rem;
    }
    .nav-wrap {
      display: none;
    }
    .keypal-card-buy {
      display: none;
    }
    .menu {
      display: block;
      width: 0.36rem;
      // transform: rotate(90deg);
      transition: transform 0.3s;
      &.active {
        transform: rotate(-90deg);
      }
    }
  }
}
</style>
