<template>
  <div class="menu-mobile" @click="$emit('close')">
    <div class="menu-content">
      <div
        class="menu-item"
        v-for="(navItem, index) in mobileDropDownList"
        :key="index"
      >
        <div
          class="menu-item-title d-flex ai-center jc-center"
          @click.stop="onNavItem(navItem)"
        >
          <img class="icon" v-if="navItem.icon" :src="navItem.icon" />
          <div>{{ navItem.title }}</div>
          <img
            class="arrow-down"
            :class="{ active: navItem.isShow }"
            v-if="navItem.children"
            src="../../assets/images/navigation/arrow-down.png"
          />
        </div>
        <div class="nav-item-list-box" v-if="navItem.isShow">
          <div
            class="nav-item-list"
            v-for="(subItem, index) in navItem.children"
            :key="index"
          >
            <div
              class="text title d-flex ai-center jc-center"
              :data-lang="subItem.lang"
              @click="onSubtitle(subItem)"
            >
              {{ subItem.subtitle }}
            </div>
            <div :class="{ line: index !== navItem.children.length - 1 }"></div>
          </div>
        </div>
        <div
          :class="{
            line: index !== mobileDropDownList.length - 1 && !navItem.isShow,
          }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mobileDropDownList: [
        {
          // icon: require('../assets/menumobile/github.png'),
          title: this.$t('common.product'),
          isShow: false,
          children: [
            {
              subtitle: 'KeyPal Plus',
              routePath: '/keypalplus',
              // url:
              //   this.$i18n.locale === 'zh'
              //     ? 'https://shop95838799.m.youzan.com/wscgoods/detail/2797rrhqu8szbig'
              //     : 'https://keypalwallet.mystrikingly.com/store/products/keypal-plus',
            },
            {
              subtitle: 'KeyPal Card',
              // routePath: '/keypal-card',
              local: true,
              url: `https://card.keypal.pro/`,
              // url:
              //   this.$i18n.locale === 'zh'
              //     ? 'https://shop95838799.m.youzan.com/wscgoods/detail/2od5z9zzdfzkn'
              //     : 'https://keypalwallet.mystrikingly.com/store/products/keypal-hardware-wallet',
            },
            {
              subtitle: this.$t('navigation.product.label_2'),
              // url: 'https://keypalwallet.mystrikingly.com/store/products/keypal-mnemonic-secret-box',
              routePath: '/safebox',
            },
            {
              subtitle: this.$t('navigation.product.label_3'),
              url:
                this.$i18n.locale === 'zh'
                  ? 'https://shop95838799.m.youzan.com/wscgoods/detail/366w49k8dlkyf'
                  : 'https://www.keypal.store/products/keypal-leather-case',
            },
            {
              subtitle: this.$t('navigation.product.label_4'),
              url:
                this.$i18n.locale === 'zh'
                  ? 'https://shop95838799.m.youzan.com/v2/showcase/homepage?alias=sptfzUCg0j'
                  : 'https://keypalwallet.mystrikingly.com/',
            },
          ],
        },
        {
          title: this.$t('navigation.upgrade.label_1'),
          routePath: '/firmwareupgrade',
        },
        {
          title: this.$t('common.learn'),
          isShow: false,
          children: [
            {
              subtitle: this.$t('navigation.learn.label_1'),
              routePath: '/security',
            },
            // {
            //   subtitle: this.$t('navigation.learn.label_3'),
            //   routePath: '/academy',
            // },
            {
              subtitle: this.$t('navigation.learn.label_3_1'),
              routePath: '/academy',
            },
            {
              subtitle: this.$t('navigation.learn.label_3_2'),
              // routePath: '/keypal-card-instructions',
              local: true,
              url: `https://card.keypal.pro/tutorials`,
            },
          ],
        },
        {
          title: this.$t('common.support'),
          isShow: false,
          children: [
            {
              subtitle: this.$t('navigation.support.label_1'),
              url:
                this.$i18n.locale === 'en'
                  ? 'https://keypal.gitbook.io/en/keypal-tutorial'
                  : 'https://keypal.gitbook.io/zh-cn/keypal-tutorial',
            },
            {
              subtitle: this.$t('navigation.support.label_2'),
              url:
                this.$i18n.locale === 'en'
                  ? 'https://keypal.gitbook.io/en/buy-faq'
                  : 'https://keypal.gitbook.io/zh-cn/buy-faq',
            },
            {
              subtitle: this.$t('navigation.support.label_3'),
              url: 'https://fans.tokenpocket.pro/',
            },
          ],
        },
        {
          title: this.$t('common.language'),
          isShow: false,
          children: [
            { subtitle: 'English', lang: 'en' },
            { subtitle: '中文(简体)', lang: 'zh' },
            { subtitle: '中文(繁體)', lang: 'zh-tw' },
          ],
        },
      ],
    };
  },
  // computed: {
  //   mobileDropDownList() {
  //     return [
  //       {
  //         // icon: require('../assets/menumobile/github.png'),
  //         title: this.$t('common.product'),
  //         isShow: false,
  //         children: [
  //           {
  //             subtitle: 'KeyPal',
  //             url:
  //               this.$i18n.locale === 'zh'
  //                 ? 'https://shop95838799.m.youzan.com/wscgoods/detail/2od5z9zzdfzkn'
  //                 : 'https://keypalwallet.mystrikingly.com/store/products/keypal-hardware-wallet',
  //           },
  //           {
  //             subtitle: 'KeyPal Plus',
  //             url:
  //               this.$i18n.locale === 'zh'
  //                 ? 'https://shop95838799.m.youzan.com/wscgoods/detail/2797rrhqu8szbig'
  //                 : 'https://keypalwallet.mystrikingly.com/store/products/keypal-plus',
  //           },
  //           {
  //             subtitle: this.$t('navigation.product.label_2'),
  //             url: 'https://keypalwallet.mystrikingly.com/store/products/keypal-mnemonic-secret-box',
  //           },
  //           {
  //             subtitle: this.$t('navigation.product.label_3'),
  //             url:
  //               this.$i18n.locale === 'zh'
  //                 ? 'https://shop95838799.m.youzan.com/wscgoods/detail/366w49k8dlkyf'
  //                 : 'https://www.keypal.store/products/keypal-leather-case',
  //           },
  //           {
  //             subtitle: this.$t('navigation.product.label_4'),
  //             url:
  //               this.$i18n.locale === 'zh'
  //                 ? 'https://shop95838799.m.youzan.com/v2/showcase/homepage?alias=sptfzUCg0j'
  //                 : 'https://keypalwallet.mystrikingly.com/',
  //           },
  //         ],
  //       },
  //       {
  //         title: this.$t('navigation.upgrade.label_1'),
  //         routePath: '/firmwareupgrade',
  //       },
  //       {
  //         title: this.$t('common.support'),
  //         isShow: false,
  //         children: [
  //           {
  //             subtitle: this.$t('navigation.support.label_1'),
  //             url:
  //               this.$i18n.locale === 'en'
  //                 ? 'https://keypal.gitbook.io/en/keypal-tutorial'
  //                 : 'https://keypal.gitbook.io/zh-cn/keypal-tutorial',
  //           },
  //           {
  //             subtitle: this.$t('navigation.support.label_2'),
  //             url:
  //               this.$i18n.locale === 'en'
  //                 ? 'https://keypal.gitbook.io/en/buy-faq'
  //                 : 'https://keypal.gitbook.io/zh-cn/buy-faq',
  //           },
  //           {
  //             subtitle: this.$t('navigation.support.label_3'),
  //             url: 'https://fans.tokenpocket.pro/',
  //           },
  //         ],
  //       },
  //       {
  //         title: this.$t('common.language'),
  //         isShow: false,
  //         children: [
  //           { subtitle: '中文(简体)', lang: 'zh' },
  //           { subtitle: '中文(繁體)', lang: 'zh-tw' },
  //           { subtitle: 'English', lang: 'en' },
  //         ],
  //       },
  //     ]
  //   },
  // },
  mounted() {
    // 监听，除了点击自己，点击其他地方将自身隐藏
    // document.addEventListener('click', this.onOutside)
  },
  methods: {
    onOutside(e) {
      const languageBtn = this.$refs.languageBtn;
      if (!languageBtn.contains(e.target)) {
        const contentWrap = document.querySelector('.menu-content');
        if (contentWrap) {
          if (!contentWrap.contains(e.target)) {
            this.isShowLanguageBox = false;
            this.isShowLanguageListBox = false;
          }
        }
      }
    },
    onNavItem(navItem) {
      if (navItem.url) {
        let target = '_blank'
        if (navItem.local) {
          target = '_self'
        }
        window.open(navItem.url, target);
      } else if (navItem.children) {
        navItem.isShow = !navItem.isShow;
      } else if (navItem.routePath) {
        this.$router.push({ path: navItem.routePath });
      }
    },
    onSubtitle(subItem) {
      if (subItem.url) {
        let target = '_blank'
        if (subItem.local) {
          target = '_self'
        }
        window.open(subItem.url, target);
      } else if (subItem.lang) {
        this.$i18n.locale = subItem.lang;
      } else if (subItem.routePath) {
        this.$router.push({ path: subItem.routePath });
      }
    },
    onChangeLang(e) {
      // this.$store.commit('changeLanguage', e.target.dataset.lang)
      if (e.target.dataset.lang) {
        console.log('e', e.target.dataset.lang);
      }
    },
    openUrl(url, index) {
      if (index === 1) {
        window.open(url, '_self');
      } else {
        window.open(url);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-mobile {
  position: fixed;
  z-index: 9997;
  top: 1rem;
  left: 0;
  width: 100vw;
  height: calc(100vh - 1rem);
  background: #fff;
  .menu-content {
    width: 100vw;
    background: #fff;
    .menu-item {
      color: #333;
      font-size: 0.32rem;
      font-family: Inter-Regular;
      .menu-item-title {
        height: 0.99rem;
        .icon {
          margin-right: 0.07rem;
          width: 0.26rem;
          height: 0.26rem;
        }
        .arrow-down {
          margin-left: 0.1rem;
          transform-origin: center center;
          // transform: rotate(0);
          width: 0.24rem;
          height: 0.24rem;
          &.active {
            transform: rotate(180deg);
          }
        }
      }
      .line {
        width: 94vw;
        // width: 100%;
        margin: 0 auto;
        height: 0.01rem;
        background: #eee;
      }
      .nav-item-list-box {
        background: #f5f5f5;
        .nav-item-list {
          .title {
            height: 0.99rem;
            text-align: center;
            font-size: 0.28rem;
          }
          .line {
            background: #fff;
          }
        }
      }
    }
  }
}
</style>
