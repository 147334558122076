const buyLink = {
  computed: {
    cardLinkUrl() {
      if (this.$i18n.locale === 'zh') {
        return 'https://shop95838799.m.youzan.com/wscgoods/detail/3nlty4s43wymfg0';
      } else {
        return 'https://keypalwallet.mystrikingly.com/store/products/keypalcard';
      }
    },
  },
  methods: {
    goBuyKeyPalCard() {
      window.open(this.cardLinkUrl);
    },
  },
};

export default buyLink;
