export default {
  title_1_1: '助记词',
  title_1_2: '密盒',
  desc_1_2: '坚固，安全，易用',

  title_2_1: '全新工艺',
  title_2_2: '更牢固、更安全',
  desc_2_1:
    'Adopt full laser production process, thicken the thickness of the panel and enhance the protection ability',
  desc_2_2: 'More screw locks, more safety locks, and tighter fit',
  desc_2_3: 'Support 2 sets of 12-bit mnemonic at the same tim',
  desc_2_4:
    '采用全激光制作工艺，加厚面板厚度，增强防护能力 更多螺丝锁，更多安全锁，让面板间更紧密贴合 同时支持2套12位助记词 贴心让你安全放心',

  title_3_1_1: '惊人的',
  title_3_1_2: '三防效果',
  desc_3_1:
    '通体采用精制304不锈钢材料，让密盒在日常存储、使用中得到全方位保护 并在极端环境下，展现惊人的防护能力 做您的资产安全守护者',
  desc_3_2: '防水耐腐蚀',
  desc_3_3_1: '采用精制304不锈钢材料',
  desc_3_3_2: '不怕水，耐腐蚀',
  desc_3_3_3: '储存环境友好，无需担心"风吹日晒"',
  desc_3_3_4: '让您的秘钥经久不变',
  desc_3_4: '防火',
  desc_3_5_1: '超高的熔点温度',
  desc_3_5_2: '具有优良的防火性能、耐火性能',
  desc_3_5_3: '在每一场不受欢迎的火焰中',
  desc_3_5_4: '坚固保护，不怕"红炉火"',
  desc_3_6: '防碰撞',
  desc_3_7_1: '采用高强度制作工艺',
  desc_3_7_2: '面板更厚，贴合更紧密',
  desc_3_7_3: '在每一个意料之外的碰撞中',
  desc_3_7_4: '牢牢保护内部的每一个字符',

  title_4_1: '字母板',
  desc_4_1_1: '多功能字母板',
  desc_4_1_2: '让记录更便捷，更高效',
  desc_4_2_1: '全新设计，满足更多需求，6片字母板可拆卸出351片字符',
  desc_4_2_2: '支持字母大小写，增加数字和符号字符',
  desc_4_2_3: '双面设计，可展示702个字符',
  desc_4_3: '351片字符',
  desc_4_4: '支持大小写',
  desc_4_5: '支持数字和符号',
  desc_4_6: '支持双面功能',

  title_5_1: '操作示意图',
  desc_5_1: '助记词钢板使用步骤，简单易学',
  desc_5_2: '步骤 1',
  desc_5_3: '旋转打开密盒，逆时针打开',
  desc_5_4: '步骤 2',
  desc_5_5: '用螺丝刀分别拧开5个螺丝',
  desc_5_6: '步骤 3',
  desc_5_7: '松开螺丝后向上旋转打开密盒',
  desc_5_8: '步骤 4',
  desc_5_9: '将助记词对应字母分别放进字母槽',
  desc_5_10: '步骤 5',
  desc_5_11: '放置好助记词对应字母后，核验无误后合上密盒，并拧紧5个螺丝',

  title_6_1: '产品参数 / 包装',
  desc_6_1: '助记词密盒',
  desc_6_3: '字母板',
  desc_6_5: 'EVA 包',
  desc_6_7: '说明书/螺丝/螺丝刀',

  desc_7_1: '密盒规格参数',
  desc_7_2: '尺寸',
  desc_7_3: '大小',
  desc_7_4: '字母版规格',
  desc_7_5: '3片一套 总共351块字母块',
};
