export default {
  title_1: '為什麼您需要一個硬件錢包？',
  desc_1_1: '由於Web3是建立在智能合約之上，給部分區塊鏈用戶帶來了諸多風險',
  desc_1_2:
    '當您需要創建錢包或簽署任何交易時，您使用的手機和電腦存在被入侵、病毒植入、粘貼板被監控等未知風險。此時，您需要一款安全的設備在Web3世界暢遊。',
  desc_1_3: '因此，硬件錢包是您最佳的一個選擇。',

  title_2: '為什麼硬件錢包安全？',
  desc_2_1: '搭載安全芯片',
  desc_2_2: '幫助您安全創建錢包',
  desc_2_3: '安全儲存信息',
  desc_2_4: '離線存儲和管理私鑰',
  desc_2_5: '避免未知風險入侵',
  desc_2_6: '遠離詐騙',

  title_3: '為什麼是KeyPal硬件錢包？',
  desc_3_1: '它搭載雙芯片',
  desc_3_2: 'KeyPal硬件錢包搭載MCU和SE安全雙芯片，具有防篡改和安全保護功能。',
  desc_3_3: '防偽認證',
  desc_3_4:
    '每台KeyPal硬件錢包設備在生產過程中都有唯一的身份證明，設備背面激光雕刻有唯一的序列號。身份證書用於設備在線防偽驗證。',
  desc_3_5: '隱私數據加密傳輸',
  desc_3_6:
    'KeyPal硬件錢包通過藍牙和USB雙通道與配套應用進行通信。在藍牙通信過程中，鏈路層不會傳輸任何敏感隱私數據，杜絕通過藍牙通信截獲任何敏感數據。',
  desc_3_7: 'PIN碼保護',
  desc_3_8:
    'KeyPal硬件錢包需在創建或恢復錢包時設置開機PIN碼，並在每一次開機時確輸入PIN碼才可繼續使用。如PIN碼連續輸入錯誤，硬件錢包將自動重置為出廠設置，清空其中保存的所有錢包數據，以此保證硬件錢包在他人誤拿或丟失、被盜等情況下不被他人查看和使用。',
  desc_3_9:
    'KeyPal硬件錢包遵循BIP32/BIP39/BIP44協議，為助記詞的生成、管理和保護提供離線、離網的安全環境。通過藍牙加密通信、PIN碼保護和2.0全彩大屏顯示，為用戶顯示每一筆區塊鏈交易簽名，即讓用戶“所見即所簽”，保護所有交易數據，防止被篡改，讓一切攻擊無所遁形。',

  SE_desc_1: '1. 內置真隨機數發生器',
  SE_desc_2: '2. 多重安全存儲',
  SE_desc_3: '3. 確保私鑰不脫離芯片',
  SE_desc_4: '4. 抗暴力破解，防止外界讀取信息',
  MCU_desc_1: '1. 銀行金融級加密',
  MCU_desc_2: '2. 系統安全和篡改檢測',
  MCU_desc_3: '3. 抵禦外部攻擊和代碼注入',
};
