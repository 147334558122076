export default {
  title_1: '操作說明',
  operation_desc_1: '鋁合金外殼，更好的保護硬件錢包',
  operation_desc_2: '向左按鍵',
  operation_desc_3: '在顯示屏中向左移動選擇或返回上一頁',
  operation_desc_4: '向下按鍵',
  operation_desc_5: '在顯示屏中向下移動選擇',
  operation_desc_6: '2英寸超大屏，所見即所簽',
  operation_desc_7: '向上按鍵',
  operation_desc_8: '在顯示屏中向上移動選擇',
  operation_desc_9: '向右按鍵',
  operation_desc_10: '在顯示屏中向右移動選擇或確認當前操作',
  operation_desc_11: '確認按鍵',
  operation_desc_12: '確認操作或進入菜單頁面，長按3秒左右開機或關機',
  operation_desc_13: 'Type-C接口',
  operation_desc_14: '用於充電和固件升級',

  title_2: '如何開始使用 KeyPal',
  get_start_desc_1: '初始化',
  get_start_desc_2: '長按 "',
  get_start_desc_3: '"確認按鍵3秒左右開機，它將顯示 KP_XXX 未激活。',
  get_start_desc_4: '激活步驟（創建錢包）:',
  get_start_desc_5: '1. 點擊',
  get_start_desc_6: '進入主菜單。',
  get_start_desc_7: '2. 按向下按鍵',
  get_start_desc_8: '然後選擇"創建錢包"。',
  get_start_desc_9:
    '3. 按順序備份並驗證助記詞，並設置PIN碼，即可成功激活設備。',

  get_start_desc_10: '請使用紙筆或KEYPAL助記詞密盒記錄保存助記詞',
  get_start_desc_11: '請按順序記錄助記詞並驗證',

  title_3: '如何將 KeyPal 與 TokenPocket 連接',
  connect_desc_1: '1. 確保KeyPal的藍牙已開啟：選擇【設置】-【藍牙】。',
  connect_desc_2: '2. 在 TokenPocket 中，點擊錢包 "',
  connect_desc_3: '" 在“選擇網絡”頁麵點擊KeyPal硬件錢包。',
  connect_desc_4:
    '3. 選擇添加設備，TokenPocket會開始搜索設備，選擇當前藍牙名稱對應的設備。',
  connect_desc_4_2:
    '（溫馨提示：藍牙名稱可以在【關於】-【設備信息】中查看，如果客戶端找不到對應的藍牙設備，請重啟硬件設備，重複連接步驟。',
  connect_desc_5:
    '設備連接成功後，TokenPocket資產頁面右上角會成功顯示KeyPal藍牙連接圖標。',
  connect_desc_6: '連接成功',

  title_4: '批量添加',
  batch_desc_1: '1. 使用KeyPal通過藍牙連接TokenPocket APP。',
  batch_desc_2: '2. 點擊App右上角帶有藍牙連接標誌的硬件錢包圖標。',
  batch_desc_3: '3. 進入“KeyPal管理”，點擊"添加錢包"。',
  batch_desc_4: '4. 進入頁面，點擊右上角的“批量添加”， 即可完成。',
  batch_desc_5: 'KeyPal如何連接TokenPocket，請查看上一頁',
  batch_desc_6: '點擊查看批量錢包',

  title_5: '兌換、轉賬等，使用PIN碼進行確認簽名',
  exchange_desc_1: '使用KeyPal硬件錢包連接TokenPocket App，執行以下操作：',
  exchange_desc_2:
    '在下圖中，第一行最右邊圖片中的“*”字符對應於第二行第二張圖片中顯示的數字。您只需要按照KeyPal硬件錢包設備中顯示的數字號碼創建錢包時最初設置的PIN碼即可。',
  exchange_desc_3: '客戶端根據KeyPal設備顯示數字的位置輸入',

  tutorial_title_1: '如何驗證您的 KeyPal硬件錢包設備？',
  tutorial_desc_1: '進入設備管理，進入設備安全。',
  tutorial_title_2: '如何驗證您的地址是否屬於KeyPal？',
  tutorial_desc_2:
    '將 KeyPal硬件錢包 通過藍牙連接TokenPocket，然後在主界面選擇收款-校驗。',
  tutorial_title_3: '如何使用KeyPal硬件錢包收款？',
  tutorial_desc_3: '將 KeyPal硬件錢包 與 TokenPocket 連接，然後選擇"收款"。',
  tutorial_title_4: '如何使用 KeyPal硬件錢包發送交易？',
  tutorial_desc_4:
    '將 KeyPal硬件錢包 與 TokenPocket 連接，選擇"發送"和"轉賬"。',
  tutorial_title_5: '交易時如何輸入PIN碼？',
  tutorial_desc_5: '',
};
