export default {
  title: 'KeyPal Manager',
  desc: 'KeyPal固件升级工具',
  download: '下载 KeyPal Bridge 客户端',
  onLine: '在线升级',
  goDesktop: '请前往电脑端操作',
  macTitle: 'Mac(苹果自研发芯片)',
  question_1: '如何通过客户端升级KeyPal？',
  question_2: '如何在线进行升级？',
}
