<template>
  <div
    class="button button-mobile d-flex ai-center jc-center"
    @click="goBuyKeyPal"
  >
    <span class="text">{{ $t('home.shopNow') }}</span>
    <div
      class="img-wrap d-flex ai-center jc-center"
      :class="{ security: security }"
    >
      <div class="img-bg"></div>
    </div>
  </div>
</template>

<script>
import buyLink from '../../mixins/buyLink';
export default {
  props: {
    security: {
      type: Boolean,
    },
    isKeyPalPlus: {
      type: Boolean,
    },
    isHome: {
      type: Boolean,
    },
  },
  mixins: [buyLink],
};
</script>

<style lang="scss" scoped>
.button {
  margin-top: 1.03rem;
  width: fit-content;
  background: #202020;
  border-radius: 0.4rem;
  padding: 0.05rem 0.05rem 0.05rem 0.26rem;
  cursor: pointer;
  span {
    font-size: 0.16rem;
    font-family: Inter-Regular;
    color: #ffffff;
  }
  .img-wrap {
    margin-left: 0.42rem;
    position: relative;
    width: 0.48rem;
    height: 0.48rem;
    background: #000000;
    border-radius: 50%;
    &.security {
      background: #183968;
    }
    .img-bg {
      width: 0.26rem;
      height: 0.26rem;
      background: url('../../assets/images/home/shop-card-1.png') no-repeat;
      background-size: 100% 100%;
    }
  }
}
.button:hover {
  background: #2980fe;
  .img-wrap {
    background: #1c72f0;
    &.security {
      background: #1a68da;
    }
    .img-bg {
      background: url('../../assets/images/home/shop-card-2.png') no-repeat;
      background-size: 100% 100%;
    }
  }
}
.button-mobile {
  display: none;
}
@media screen and (max-width: 767px) {
  .button {
    margin: 0 auto;
    margin-top: 0.4rem;
    border-radius: 0.66rem;
    padding: 0.08rem 0.08rem 0.08rem 0.4rem;
    span {
      font-size: 0.28rem;
    }
    .img-wrap {
      width: 0.82rem;
      height: 0.82rem;
      .img-bg {
        width: 0.44rem;
        height: 0.44rem;
      }
    }
  }
  .button:hover {
    background: #202020;
    .img-wrap {
      background: #000000;
      .img-bg {
        background: url('../../assets/images/home/shop-card-1.png') no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .button-mobile {
    display: flex;
    margin-top: 1rem;
  }
}
</style>
