<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  created() {
    document.title = this.$t('common.documentTitle')
  },
}
</script>
