<template>
  <div class="option-card" :class="{ mobile: card.colors }">
    <div class="title">{{ card.title }}</div>
    <div
      class="option-list"
      :class="{ 'keypal-display': card.disPlay, noIcon: card.noIcon }"
    >
      <div
        class="option-item"
        v-for="(option, index) in card.option"
        :key="index"
      >
        <div class="d-flex ai-center" v-if="option.icon">
          <img :src="option.icon" />
          <span class="text">{{ option.label }}</span>
        </div>
        <div class="desc text">{{ option.desc }}</div>
      </div>
      <div class="colors d-flex" v-if="card.colors">
        <div
          class="color reset d-flex ai-center jc-center"
          :class="{ 'color-border': curIndex === 0 }"
          @click="onColor(0)"
        >
          <img
            v-if="curIndex === 0"
            src="../../assets/images/specification/reset.png"
          />
          <img v-else src="../../assets/images/specification/reset-on.png" />
        </div>
        <div
          class="color"
          v-for="(color, index) in card.colors"
          :key="index"
          @click="onColor(index + 1)"
          :class="{
            black: index === 0,
            sliver: index === 1,
            golden: index === 2,
            'color-border': curIndex === index + 1,
          }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    card: {
      type: Object,
    },
  },
  data() {
    return {
      curIndex: 0,
    };
  },
  methods: {
    onColor(index) {
      this.curIndex = index;
      this.$emit('onColor', index);
    },
  },
};
</script>

<style lang="scss" scoped>
.option-card {
  &:nth-child(n + 2) {
    margin-top: 0.46rem;
  }
  .text {
    color: #fff;
  }
  .title {
    font-size: 0.18rem;
    color: #5d5d5d;
    text-align: left;
    line-height: 0.21rem;
  }
  .option-list {
    margin-top: 0.13rem;
    &.keypal-display {
      max-width: 2.2rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .option-item {
        min-width: 1rem;
      }
      .option-item:nth-child(n + 2) {
        margin-top: 0;
      }
      .option-item:nth-child(n + 3) {
        margin-top: 0.2rem;
      }
    }
    &.noIcon {
      margin-top: 0.07rem;
    }
    .option-item {
      &:nth-child(n + 2) {
        margin-top: 0.2rem;
      }
      img {
        width: 0.14rem;
      }
      span {
        margin-left: 0.05rem;
        font-family: 'SemiBold';
      }
      .desc {
        margin-top: 0.06rem;
        font-size: 0.12rem;
      }
    }
    .colors {
      margin-top: 0.06rem;
      .reset {
        img {
          width: 0.1rem;
        }
      }
      .color {
        &:nth-child(n + 2) {
          margin-left: 0.06rem;
        }
        width: 0.24rem;
        height: 0.24rem;
        border-radius: 0.1rem;
        background: #151515;
        border: 0.01rem solid #5d5d5d;
        cursor: pointer;
        &.black {
          border: 0.01rem solid #2c2c2c;
          background: linear-gradient(180deg, #2c2c2c 0%, #000000);
        }
        &.sliver {
          border: 0.01rem solid #f6f6f6;
          background: linear-gradient(180deg, #f6f6f6, #b9b9b9);
        }
        &.golden {
          border: 0.01rem solid #ffe0b7;
          background: linear-gradient(180deg, #ffe0b7 0%, #d6ac75);
        }
        &.color-border {
          border: 0.01rem solid #2980fe;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .option-card {
    &.mobile {
      position: absolute;
      top: -1.6rem;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      .option-list {
        margin-top: 0;
        .option-item {
          display: none;
        }
        .colors {
          margin-left: 0.3rem;
          margin-top: 0;
          .color {
            width: 0.54rem;
            height: 0.54rem;
            border-radius: 0.2rem;
          }
        }
        .color {
          border: 0.02rem solid #5d5d5d;
          &.color-border {
            border: 0.02rem solid #2980fe;
          }
        }
      }
    }
    .title {
      padding: 0;
      font-size: 0.26rem;
    }
    .option-list {
      margin-top: 0.2rem;
      &.display {
        max-width: 2rem;
        .option-item:nth-child(n + 2) {
          margin-top: 0.3rem;
        }
      }
      .option-item {
        &:nth-child(n + 2) {
          margin-top: 0.3rem;
        }
        img {
          width: 0.24rem;
        }
        span {
          white-space: nowrap;
          margin-left: 0.1rem;
          font-size: 0.22rem;
        }
        .desc {
          margin-top: 0.12rem;
          font-size: 0.18rem;
        }
      }
    }
  }
}
</style>
