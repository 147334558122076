export default {
  product: {
    label_1: 'KeyPal',
    label_2: 'Secret Box',
    label_3: 'Leather Case',
    label_4: 'All Accessories',
    label_5: 'KeyPal Card',
  },
  upgrade: {
    label_1: 'Upgrade',
  },
  // learn标题下面的描述我不知道是什么文案
  learn: {
    label_1: 'Security',
    desc_1: 'Interpret Placeholders',
    label_2: 'Crypto Assets',
    desc_2: 'Interpret Placeholders',
    label_3: 'Academy',
    desc_3: 'Interpret Placeholders',
    label_3_1: 'KeyPal Plus Instructions',
    label_3_2: 'KeyPal Card Instructions',
  },
  others: {
    label_1: 'Abouts',
    label_2: 'Privacy Policy',
    label_3: 'Terms Of Use',
  },
  support: {
    label_1: 'Help Center',
    label_2: 'FAQ For Purchase',
    label_3: 'Community',
  },
}
