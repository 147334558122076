<template>
  <div class="home">
    <Header />
    <div class="open-container">
      <div class="content d-flex jc-between">
        <div class="left-wrap">
          <div class="title" @click="blockchain">
            {{ $t('home.header.title') }}
          </div>
          <div class="subtitle">{{ $t('home.header.subtitle') }}</div>
          <Button isHome />
        </div>
        <div class="keypal-wrap">
          <img class="keypal-3" src="../assets/images/home/keypal-3.png" />
          <img class="keypal-2" src="../assets/images/home/keypal-2.png" />
        </div>
        <Button isHome />
        <div class="open-bg-wrap">
          <img class="bg-1" src="../assets/images/home/open-bg-1.png" />
          <img class="bg-2" src="../assets/images/home/open-bg-2.png" />
          <img class="bg-3" src="../assets/images/home/open-bg-3.png" />
          <img class="light" src="../assets/images/home/light.png" />
        </div>
        <img class="bg-4" src="../assets/images/home/open-bg-4.png" />
      </div>
      <div class="mobile-open-bg-wrap">
        <img class="bg-1" src="../assets/images/home/open-bg-1.png" />
        <img class="bg-2" src="../assets/images/home/open-bg-2.png" />
        <img class="bg-3" src="../assets/images/home/open-bg-3.png" />
        <img class="bg-4" src="../assets/images/home/open-bg-4.png" />
        <img
          class="light-mobile"
          src="../assets/images/home/light-mobile.png"
        />
      </div>
    </div>
    <div class="linear-gradient"></div>
    <Introduce />
    <Function />
    <Software />
    <Specification />
    <Footer />
  </div>
</template>

<script>
import Header from '../components/common/Header.vue';
import Introduce from '../components/home/Introduce.vue';
import Function from '../components/home/Function.vue';
import Software from '../components/home/Software.vue';
import Specification from '../components/home/Specification.vue';
import Footer from '../components/common/Footer.vue';
import Button from '../components/common/Button.vue';

import buyLink from '../mixins/buyLink';
export default {
  name: 'Home',
  components: {
    Header,
    Introduce,
    Function,
    Software,
    Specification,
    Footer,
    Button,
  },
  mixins: [buyLink],
  methods: {
    blockchain() {
      // this.$router.push({
      //   path: '/firmwareupgrade',
      // })
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  .open-container {
    padding: 0.6rem 0 1.7rem;
    background: #fbfdff;

    position: relative;
    .content {
      position: relative;
      padding-left: 0.2rem;
      .left-wrap {
        position: relative;
        z-index: 20;
        .title {
          max-width: 4.52rem;
          font-size: 0.44rem;
          line-height: 0.62rem;
          text-align: left;
        }
        .subtitle {
          margin-top: 0.3rem;
          max-width: 3.17rem;
          font-size: 0.22rem;
          line-height: 0.26rem;
          text-align: left;
        }
        .button {
          display: flex;
          // margin-top: 2rem;
          // margin-left: 1rem;
        }
      }
      .keypal-wrap {
        z-index: 10;
        .keypal-2 {
          width: 3.2rem;
          transform: translate(-1rem, -0.8rem);
        }
        .keypal-3 {
          width: 3.54rem;
          transform: translate(-0.25rem, 0.65rem);
        }
      }
      .button-mobile {
        display: none;
      }
      .open-bg-wrap {
        overflow: hidden;
        width: -webkit-fill-available;
        height: 7.76rem;
        position: absolute;
        top: 0;

        .bg-1 {
          position: absolute;
          max-width: 10.34rem;
          top: -0.2rem;
          left: 3rem;
        }
        .bg-2 {
          position: absolute;
          left: 3rem;
          top: 4.5rem;
          width: 0.99rem;
        }
        .bg-3 {
          position: absolute;
          right: 0.1rem;
          width: 1.01rem;
        }
        .light {
          position: absolute;
          max-width: 15.42rem;
          top: 0;
          left: 0;
          z-index: 11;
          opacity: 0.6;
        }
      }
      .bg-4 {
        position: absolute;
        width: 1.17rem;
        top: -0.8rem;
        left: -0.7rem;
      }
    }
    .mobile-open-bg-wrap {
      display: none;
    }
  }
  .linear-gradient {
    width: 100%;
    height: 0.6rem;
    background: linear-gradient(180deg, rgba(251, 253, 255, 0), #fbfdff);
    background: linear-gradient(#fbfdff, rgba(251, 253, 255, 0));
  }
}
@media screen and (max-width: 767px) {
  .home {
    .open-container {
      padding: 0.3rem 0 1.5rem 0;
      .content {
        padding: 0 0.32rem;
        flex-direction: column;
        .left-wrap {
          .title {
            padding: 0 0.5rem;
            font-size: 0.52rem;
            line-height: 0.8rem;
            max-width: none;
            text-align: center;
          }
          .subtitle {
            max-width: none;
            padding: 0 0.7rem;
            margin: 0;
            margin-top: 0.2rem;
            font-size: 0.32rem;
            text-align: center;
            line-height: 0.44rem;
          }
          .button {
            display: none;
          }
        }
        .keypal-wrap {
          margin-top: 1.5rem;
          .keypal-2 {
            transform: translate(-0.3rem, -0.8rem);
          }
          .keypal-3 {
            transform: translate(0.5rem, 0.65rem);
          }
        }
        .button-mobile {
          display: flex;
          margin-top: 1rem;
        }
        .open-bg-wrap {
          display: none;
        }
      }
      .mobile-open-bg-wrap {
        pointer-events: none;
        overflow: hidden;
        display: block;
        position: absolute;
        width: 100%;
        height: 15rem;
        top: 0;
        .bg-1 {
          position: absolute;
          width: 100%;
          top: 4.6rem;
          left: 0.7rem;
        }
        .bg-2 {
          position: absolute;
          width: 0.99rem;
          top: 8rem;
          left: 0.2rem;
        }
        .bg-3 {
          position: absolute;
          width: 1.01rem;
          right: 0;
          top: 3.7rem;
        }
        .bg-4 {
          display: none;
          position: absolute;
          width: 1.01rem;
        }
        .light-mobile {
          position: absolute;
          width: 100%;
          top: 4.3rem;
          z-index: 99;
          opacity: 0.6;
        }
      }
    }
  }
}
</style>
