export default {
  title_1_1: 'Secret',
  title_1_2: 'Safe Box',
  desc_1_2: 'Solid, Secure, and Easy',

  title_2_1: 'Brand New',
  title_2_2: 'Design',
  desc_2_1:
    'Adopt full laser production process, thicken the thickness of the panel and enhance the protection ability',
  desc_2_2: 'More screw locks, more safety locks, and tighter fit',
  desc_2_3: 'Support 2 sets of 12-bit mnemonic at the same time',
  desc_2_4:
    'Adopt full laser production process, thicken the thickness of the panel and enhance the protection ability, More screw locks, more safety locks, and tighter fit, Support 2 sets of 12-bit mnemonic at the same time',

  title_3_1_1: 'Amazing 3-Proof',
  title_3_1_2: 'Characteristics',
  desc_3_1:
    'The whole body is precisely manufactured with 304 stainless steel which gives it fireproof, heat resistance and anti-corrosion features',
  desc_3_2: 'Water Proof',
  desc_3_3_1: 'Refined 304 stainless steel material',
  desc_3_3_2: 'Waterproof and corrosion proof',
  desc_3_3_3: 'Storage environment friendly',
  desc_3_3_4: 'Easy to protect the keys',
  desc_3_4: 'Fire Proof',
  desc_3_5_1: 'Super high melting temperature',
  desc_3_5_2: 'Excellent fire performance and resistance',
  desc_3_5_3: 'Keep your key safe',
  desc_3_5_4: 'in every unexpected fire',
  desc_3_6: 'Collision Proof',
  desc_3_7_1: 'Using high-strength manufacturing process',
  desc_3_7_2: 'Thicker panels for a tighter fit',
  desc_3_7_3: 'in every unexpected collision',
  desc_3_7_4: 'Firmly protect every letter inside',

  title_4_1: 'Alphabet Panel',
  desc_4_1_1: 'Pro version of Alphabet Panel',
  desc_4_1_2: 'More convenience, better efficience',
  desc_4_2_1:
    'New design to meet more needs, 6 alphabet panels can be disassembled to produce 351 pieces',
  desc_4_2_2:
    'Support uppercase and lowercase, add number and symbol characters',
  desc_4_2_3:
    'Double-sided design, can display 702 characters',
  desc_4_3: '351 blocks',
  desc_4_4: 'uppercase/lowercase',
  desc_4_5: 'Numbers/symbol character',
  desc_4_6: 'Support double sides',

  title_5_1: 'Operation Diagram',
  desc_5_1:
    'Compatible with Extension wallet on the website. Perfectly adapted to TokenPocket, combining security and ease of use.',
  desc_5_2: 'Step 01',
  desc_5_3: 'Fan out the front panel, open counterclockwise',
  desc_5_4: 'Step 02',
  desc_5_5: 'Use a screwdriver to loosen the 5 screws',
  desc_5_6: 'Step 03',
  desc_5_7: 'Fan upside the cover panel',
  desc_5_8: 'Step 04',
  desc_5_9: 'Enter your characters into the slots',
  desc_5_10: 'Step 05',
  desc_5_11:
    'close the front panel after verification and tighten the 5 screws',

  title_6_1: 'Product Specification/ Package',
  desc_6_1: 'Secret Box Pro',
  desc_6_2:
    'Compared with traditional paper copying, Secret Box Pro has taken safety and convenience well into account, which can be easily disassembled and assembled, and it is also very convenient and fast to place the character blocks.',
  desc_6_3: 'Alphabet Panel',
  desc_6_4:
    'Compared with traditional paper copying, Secret Box Pro has taken safety and convenience well into account, which can be easily disassembled and assembled, and it is also very convenient and fast to place the character blocks.',
  desc_6_5: 'EVA Bag',
  desc_6_6:
    'Compared with traditional paper copying, Secret Box Pro has taken safety and convenience well into account, which can be easily disassembled and assembled, and it is also very convenient and fast to place the character blocks.',
  desc_6_7: 'Booklet/Screw/Screwdriver',
  desc_6_8:
    'Compared with traditional paper copying, Secret Box Pro has taken safety and convenience well into account, which can be easily disassembled and assembled, and it is also very convenient and fast to place the character blocks.',

  title_7_1: 'Technical Specifications',
  title_7_2: 'Features and Usage',
  desc_7_1: 'Secret Box Specifications',
  desc_7_2: 'Size',
  desc_7_3: 'Weight',
  desc_7_4: 'Alphabet Panel Specifications',
  desc_7_5: 'A set of three pieces, a total of 351 character blocks',
  desc_7_6: 'supports more than one set of mnemonics',
  desc_7_7:
    'supports two sets of 12-word or one set of 18- or 24-word recovery phrases following BIP39 protocol.',
  desc_7_8: 'Offline storage',
  desc_7_9:
    'By offline storage, Secret Box Pro completely eliminates the possibility of attackers stealing secret keys through the network',
  desc_7_10: 'high quality material',
  desc_7_11:
    'firm stainless-steel material is better for permanent preservation of mnemonic phrases, away from the trouble of losing.',
};
