<template>
  <div class="footer" :class="{ security: security, isKeyPal: isKeyPal }">
    <div class="content d-flex jc-between">
      <div class="follow-wrap left">
        <img class="logo" :src="logoImg" @click="goHome" />
        <div>
          <div class="footer-follows d-flex">
            <a
              href="mailto:support@keypal.pro"
              class="footer-follow"
              :class="[security ? 'security-1' : 'footer-follow-1']"
              target="_blank"
            ></a>
            <div
              class="footer-follow"
              v-for="(item, index) in followList"
              :key="index"
              :class="[security ? 'security-' + (index + 2) : item.class]"
              @click="openFollow(item)"
            ></div>
          </div>
          <div class="copyright" :class="{ security: security }">
            Copyright © 2022 KeyPal, Inc. All rights reserved.
          </div>
        </div>
      </div>
      <div class="footer-wrap flex-1 d-flex flex-wrap jc-between">
        <div
          v-for="(item, index) in footerList"
          :key="index"
          class="footer-list"
        >
          <div class="title" :class="{ security: security }">
            {{ item.title }}
          </div>

          <div class="footer-link-wrap">
            <div
              class="footer-link"
              v-for="(data, dataIndex) in item.data"
              :key="dataIndex"
            >
              <a
                class="pointer"
                :class="{ security: security }"
                :href="data.url ? data.url : 'javascript:void(0)'"
                :target="data.local ? '_self' : '_blank'"
                @click.stop="onFooterLink(data)"
                >{{ data.title }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    security: {
      type: Boolean,
    },
    isKeyPal: {
      type: Boolean,
    },
  },
  computed: {
    logoImg() {
      if (this.security) {
        return require('../../assets/images/security/logo-w.png');
      } else {
        return require('../../assets/images/home/logo.png');
      }
    },
    followList() {
      return [
        { url: 'https://github.com/KeyPalWallet', class: 'footer-follow-2' },
        { url: 'https://twitter.com/KeypalWallet', class: 'footer-follow-3' },
        { url: 'https://medium.com/@Keypal', class: 'footer-follow-4' },
      ];
    },
    footerList() {
      return [
        {
          title: this.$t('common.product'),
          data: [
            {
              title: 'KeyPal Plus',
              local: true,
              routePath: '/keypalplus',
              // url:
              //   this.$i18n.locale === 'zh'
              //     ? 'https://shop95838799.m.youzan.com/wscgoods/detail/2797rrhqu8szbig'
              //     : 'https://keypalwallet.mystrikingly.com/store/products/keypal-plus',
            },
            {
              title: this.$t('navigation.product.label_5'),
              local: true,
              // routePath: '/keypal-card',
              url: `https://card.keypal.pro/`,
            },
            // {
              // title: 'KeyPal',
              // local: true,
              // routePath: '/keypal',
              // url:
              //   this.$i18n.locale === 'zh'
              //     ? 'https://shop95838799.m.youzan.com/wscgoods/detail/2od5z9zzdfzkn'
              //     : 'https://keypalwallet.mystrikingly.com/store/products/keypal-hardware-wallet',
            // },
            {
              title: this.$t('navigation.product.label_2'),
              local: true,
              // url: 'https://keypalwallet.mystrikingly.com/store/products/keypal-mnemonic-secret-box',
              routePath: '/safebox',
            },
            {
              title: this.$t('navigation.product.label_3'),
              url:
                this.$i18n.locale === 'zh'
                  ? 'https://shop95838799.m.youzan.com/wscgoods/detail/366w49k8dlkyf'
                  : 'https://www.keypal.store/products/keypal-leather-case',
            },
          ],
        },
        {
          title: this.$t('common.learn'),
          data: [
            {
              title: this.$t('navigation.learn.label_1'),
              routePath: '/security',
              local: true,
            },
            // {
            //   title: this.$t('navigation.learn.label_2'),
            //   url: 'https://github.com/TP-Lab',
            // },
            {
              title: this.$t('navigation.learn.label_3_1'),
              routePath: '/academy',
              local: true,
            },
            {
              title: this.$t('navigation.learn.label_3_2'),
              // routePath: '/keypal-card-instructions',
              url: `https://card.keypal.pro/tutorials`,
              local: true,
            },
          ],
        },
        {
          title: this.$t('common.support'),
          data: [
            {
              title: this.$t('navigation.support.label_1'),
              url:
                this.$i18n.locale === 'en'
                  ? 'https://keypal.gitbook.io/en/keypal-tutorial'
                  : 'https://keypal.gitbook.io/zh-cn/keypal-tutorial',
            },
            {
              title: this.$t('navigation.support.label_2'),
              url:
                this.$i18n.locale === 'en'
                  ? 'https://keypal.gitbook.io/en/buy-faq'
                  : 'https://keypal.gitbook.io/zh-cn/buy-faq',
            },
            {
              title: this.$t('navigation.support.label_3'),
              url: 'https://fans.tokenpocket.pro/',
            },
          ],
        },
        {
          title: this.$t('common.others'),
          data: [
            {
              title: this.$t('navigation.others.label_1'),
              url:
                this.$i18n.locale === 'en'
                  ? 'https://keypal.gitbook.io/en/about/contact-us'
                  : 'https://keypal.gitbook.io/zh-cn/about/contact-us',
            },
            {
              title: this.$t('navigation.others.label_2'),
              url:
                this.$i18n.locale === 'en'
                  ? 'https://keypal.gitbook.io/en/policy/privacy'
                  : 'https://keypal.gitbook.io/zh-cn/policy/privacy',
            },
            {
              title: this.$t('navigation.others.label_3'),
              url:
                this.$i18n.locale === 'en'
                  ? 'https://keypal.gitbook.io/en/policy/user-agreement'
                  : 'https://keypal.gitbook.io/zh-cn/policy/user-agreement',
            },
          ],
        },
        {
          title: this.$t('common.language'),
          local: true,
          data: [
            {
              title: 'English',
              lang: 'en',
              local: true,
            },
            {
              title: '中文(简体)',
              lang: 'zh',
              local: true,
            },
            {
              title: '中文(繁体)',
              lang: 'zh-tw',
              local: true,
            },
          ],
        },
      ];
    },
  },
  methods: {
    goHome() {
      if (this.$route.path !== '/') {
        this.$router.push({ path: '/' });
      }
    },
    openFollow(item) {
      if (item.isTelegram) {
        this.url = item.url;
      } else {
        window.open(item.url);
      }
    },
    onFooterLink(data) {
      if (data.lang) {
        this.$i18n.locale = data.lang;
        document.title = this.$t('common.documentTitle');
      } else if (data.routePath) {
        this.$router.push({ path: data.routePath });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@for $i from 1 through 4 {
  .footer-follow-#{$i} {
    background: url(../../assets/images/footer/#{$i}.png)
      no-repeat
      100% /
      contain;
    cursor: pointer;
    &:hover {
      background: url(../../assets/images/footer/#{$i}-on.png)
        no-repeat
        100% /
        contain;
    }
  }
}
@for $i from 1 through 4 {
  .security-#{$i} {
    background: url(../../assets/images/security/security-#{$i}.png)
      no-repeat
      100% /
      contain;
    cursor: pointer;
    &:hover {
      background: url(../../assets/images/footer/#{$i}-on.png)
        no-repeat
        100% /
        contain;
    }
  }
}
.footer {
  padding: 0.9rem 0 1.2rem;
  &.security {
    background: #101010;
  }
  &.isKeyPal {
    background: #fff;
  }
  .content {
    .left {
      font-size: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .follow-wrap {
      .logo {
        width: 1.28rem;
        cursor: pointer;
      }
      .footer-follows {
        margin: 0.32rem 0 0.11rem;
        .footer-follow {
          width: 0.24rem;
          height: 0.24rem;
          &:nth-child(n + 2) {
            margin-left: 0.2rem;
          }
        }
      }
      .copyright {
        font-size: 0.12rem;
        font-family: Inter-Regular;
        color: rgba(16, 16, 16, 0.4);
        &.security {
          color: rgba(255, 255, 255, 0.4);
        }
      }
    }
    .footer-wrap {
      margin-left: 1.2rem;
      .footer-list {
        padding: 0 0.1rem;
        .title {
          font-size: 0.16rem;
          font-family: Inter-Regular;
          color: #101010;
          text-align: left;
          line-height: 0.2rem;
          &.security {
            color: #fff;
          }
        }
        .footer-link-wrap {
          margin-top: 0.2rem;
          :nth-child(n + 2) {
            margin-top: 0.17rem;
          }
          .footer-link {
            a {
              display: flex;
              font-size: 0.14rem;
              font-family: Inter-Regular;
              color: rgba(16, 16, 16, 0.4);
              cursor: pointer;
              &.security {
                color: rgba(255, 255, 255, 0.4);
              }
            }
            a:hover {
              color: #2980fe;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  @for $i from 1 through 4 {
    .footer-follow-#{$i} {
      background: url(../../assets/images/footer/#{$i}.png)
        no-repeat
        100% /
        contain;
      cursor: pointer;
      &:hover {
        background: url(../../assets/images/footer/#{$i}.png)
          no-repeat
          100% /
          contain;
      }
    }
  }
  @for $i from 1 through 4 {
    .security-#{$i} {
      background: url(../../assets/images/security/security-#{$i}.png)
        no-repeat
        100% /
        contain;
      cursor: pointer;
      &:hover {
        background: url(../../assets/images/security/security-#{$i}.png)
          no-repeat
          100% /
          contain;
      }
    }
  }
  .footer {
    .content {
      padding: 0 1rem;
      flex-direction: column;
      .follow-wrap {
        align-items: center;
        .logo {
          width: 2.31rem;
        }
        .footer-follows {
          margin: 0.5rem 0 0.3rem;
          justify-content: center;
          .footer-follow {
            width: 0.48rem;
            height: 0.48rem;
            &:nth-child(n + 2) {
              margin-left: 0.4rem;
            }
          }
        }
        .copyright {
          font-size: 0.22rem;
        }
      }
      .footer-wrap {
        margin-left: 0;
        margin-top: 0.8rem;
        .footer-list {
          width: 45%;
          padding: 0;
          &:nth-child(2n) {
            .title {
              text-align: right;
            }
            .footer-link-wrap {
              .footer-link {
                a {
                  justify-content: flex-end;
                  text-align: right;
                }
              }
            }
          }
          &:nth-child(n + 3) {
            margin-top: 0.5rem;
          }
          .title {
            padding: 0;
            text-align: left;
            font-size: 0.28rem;
            line-height: normal;
          }
          .footer-link-wrap {
            margin-top: 0.3rem;
            .footer-link {
              &:nth-child(n + 2) {
                margin-top: 0.3rem;
              }
              a {
                font-size: 0.26rem;
              }
              .security:hover {
                color: rgba(255, 255, 255, 0.4);
              }
              a:hover {
                color: rgba(16, 16, 16, 0.4);
              }
            }
          }
        }
      }
    }
  }
}
</style>
