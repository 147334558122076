export default {
  shopNow: '现在购买',
  preSale: '预售中',
  header: {
    title: '安全易用的硬件钱包',
    subtitle: '让您更安全、友好的存储和管理数字资产',
  },
  introduce: {
    title: '你值得拥有一个更专业的硬件钱包',
    subtitle: '安全、可信赖、易用',
    desc_1: '坚硬的外壳，避免物理损伤',
    desc_2: '军工级芯片，保障钱包安全',
    desc_3: '设备使用时，进行安全校验',
    desc_4: '私钥离线生成，本地安全保存',
    desc_5: '大屏幕显示，所见即所签',
    desc_6: 'PIN码保护，双重安全',
  },
  function: {
    title: '一个全方位的数字资产硬件钱包',
    subtitle: '满足您对数字资产的所有需求',
    func_title_1: '安全与存储',
    func_desc_1:
      '内置顶级安全芯片，生成私钥并储存在KeyPal中，除了您，没有人可以访问它。支持1000多条公链。',
    func_title_2: '购买和增值',
    func_desc_2:
      '以简单、安全和智能的方式购买BTC、ETH和其他数字资产，您可以在钱包内一站式资产增值。',
    func_title_3: '兑换与跨链',
    func_desc_3: '支持您快速且安全的进行数字资产兑换，轻松实现跨链。',
    func_title_4: '解锁更多数字资产玩法',
    func_desc_4: '可安全访问所有去中心化应用-DeFi、Dapp、GameFi和NFT等。',
  },
  software: {
    title: 'KeyPal 与 TokenPocket',
    connect: '连接方式',
    crossPlatform: '跨平台的',
    multiChains: '支持多链',
    supportDesc:
      '（支持所有EVM兼容链，如BNB智能链，Polygon，Fantom，Arbitrum等）',
    more: '更多',
  },
  specification: {
    title: 'KeyPal规格参数',
    card_1: '核心',
    option_label_1_1: '芯片',
    option_desc_1_1: 'MCU + SE',
    option_label_1_2: '证书及标准',
    option_desc_1_2: 'DOE, CEC, BEC, CE, FCC & RoHS',

    card_2: '电池及连接器',
    option_label_2_1: '电池',
    option_desc_2_1: '300 mAh',
    option_label_2_2: '连接器',
    option_desc_2_2: 'USB Type-C',

    card_3: '尺寸及重量',
    option_label_3_1: '尺寸',
    option_desc_3_1: '91 x 55 x 9.0(mm)',
    option_label_3_2: '重量',
    option_desc_3_2: '63(g)',

    card_4: '显示',
    option_label_4_1: '大小',
    option_desc_4_1: '2英寸',
    option_label_4_2: '显示屏',
    option_desc_4_2: '彩色LCD',
    option_label_4_3: '分辨率',
    option_desc_4_3: '320 x 480',

    card_5: '兼容性',
    option_desc_5_1: 'iOS, Android, macOS, Windows, Linux',

    card_6: '连接方式',
    option_desc_6_1: '蓝牙及USB Type-C',

    card_7: '材料',
    option_desc_7_1: '铝合金',

    card_8: '颜色',
    option_desc_8_1: '黑, 银, 金',

    btnTitle: '准备好开始使用KeyPal钱包',
    btnSubTitle: '安全易用的硬件钱包',
  },
}
