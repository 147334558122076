export default {
  product: {
    label_1: 'KeyPal',
    label_2: '助记词密盒',
    label_3: '皮革保护套',
    label_4: '所有产品',
    label_5: 'KeyPal Card',
  },
  upgrade: {
    label_1: '升级',
  },
  // learn标题下面的描述我不知道是什么文案
  learn: {
    label_1: '钱包安全',
    desc_1: '',
    label_2: '资产知识',
    desc_2: '',
    label_3: 'KeyPal学院',
    desc_3: '',
    label_3_1: 'KeyPal Plus 使用说明',
    label_3_2: 'KeyPal Card 使用说明',
  },
  others: {
    label_1: '关于',
    label_2: '隐私协议',
    label_3: '使用条款',
  },
  support: {
    label_1: '帮助中心',
    label_2: '购买问答',
    label_3: '社区中心',
  },
};
