export default {
  text_1_1_1: '备份，',
  text_1_1_2: '从未如此简单而且安全',
  text_1_2_1: '更多选择，',
  text_1_2_2: '满足你对私密的需求',
  text_2_1_1: '耐用，易携，',
  text_2_1_2: '不易弯折',
  text_2_2: '由CC EAL6+ 芯片保护安全',
  text_2_3: '随身携带',
  text_2_4: '抗弯折、防磁、防静电',
  text_2_5: '可支持五十万次刷卡使用',
  text_3_1:
    '由TokenPocket提供支持，您可以在使用KeyPal Card时享受完美的用户体验，',
  text_4_1: 'PIN码保护卡片安全',
  text_4_2: '卡片会在连续十次输入错误的PIN码后，自动抹除数据',
  text_4_3_1: '不仅仅是备份卡，',
  text_4_3_2: '也可以是签名卡',
  text_4_4: '卡片的多元性使用，不仅可用于备份，更可用于参与交易签名的一个环节',
  text_4_5: 'NFC感应，一触备份，简单安全。',
  text_4_6: '通过电磁场双向加密通信，快速响应，实现快速备份，告别繁琐。',
  text_5_1: 'Time to get started with your',
  text_6_1: '钱包',
  text_6_2:
    '完美融合，让您的钱包体验卓越无比，让每一张KeyPal Card都与其无缝衔接！',
  text_6_3: '备份',
  text_6_4: '让您的钱包备份简便而安全，不再因错误的备份而丢失资产',
  text_6_5: '签名',
  text_6_6: '让您用更多方式使用钱包，用你的KeyPal Card执行每一次签名',
};
