export default {
  title_1: 'Instructions',
  operation_desc_1: 'Aluminum alloy shell Protect the wallet from damage',
  operation_desc_2: 'LEFT Button',
  operation_desc_3: 'Move left in the display or back to previous page',
  operation_desc_4: 'DOWN Button',
  operation_desc_5: 'Move down in the display',
  operation_desc_6: '2-inch display To show the related transaction',
  operation_desc_7: 'UP Button',
  operation_desc_8: 'Move up in the display',
  operation_desc_9: 'RIGHT Button',
  operation_desc_10: 'Move right in the display or confirm actions',
  operation_desc_11: 'OK Button',
  operation_desc_12:
    'Confirm actions or enter the menu page, long press to turn on or off',
  operation_desc_13: 'Type-C',
  operation_desc_14: 'For charging and firmware upgrading',

  title_2: 'How to get started with KeyPal',
  get_start_desc_1: 'Initialization',
  get_start_desc_2: 'Long press "',
  get_start_desc_3:
    '" button for three seconds to power on, it will show that KP_XXX is not activated.',
  get_start_desc_4: 'Steps to activate (create wallet):',
  get_start_desc_5: '1. Click',
  get_start_desc_6: 'to enter the main menu.',
  get_start_desc_7: '2. Press the down button',
  get_start_desc_8: 'and select "Create Wallet"',
  get_start_desc_9:
    '3. Backup and verify the mnemonics in order, and set the PIN code, then the device will be successfully activated.',
  get_start_desc_10:
    'Please use pen and paper or KEYPAL mnemonic box for recording.',
  get_start_desc_11: 'Please record the mnemonics in order and verify',

  title_3: 'How to connect KeyPal with TokenPocket',
  connect_desc_1:
    "1. Make sure that the KeyPal's Bluetooth is turned on: select [Settings]-[Bluetooth].",
  connect_desc_2: '2. In the TokenPocket, click the wallet "',
  connect_desc_3:
    '" in the upper right corner, and click the KeyPal hardware wallet in "Select Network" page.',
  connect_desc_4:
    '3. Select Add Device, TokenPocket will start to search devices and select the device corresponding to the current Bluetooth name.',
  connect_desc_4_2:
    '(Reminder: The Bluetooth name can be viewed in [About]-&[Device Information]. If the client cannot find the corresponding Bluetooth device, please restart the hardware device and repeat the connecting steps.',
  connect_desc_5:
    'After the device is successfully connected, the KeyPal Bluetooth connected icon will be successfully displayed in the upper right corner of the TokenPocket assets page.',
  connect_desc_6: 'Connected successfully',

  title_4: 'Create wallets in batches',
  batch_desc_1: '1. Use KeyPal to connect the TokenPocket APP via Bluetooth.',
  batch_desc_2:
    '2. Click on the hardware wallet icon with the Bluetooth connected logo in the upper right corner of the App.',
  batch_desc_3: '3. Go to "KeyPal Management", and click "Add wallet".',
  batch_desc_4:
    '4. Enter the page, and click “Batch Add” in the upper right corner. That is done.',
  batch_desc_5: 'How KeyPal connect with TokenPocket, please view above',
  batch_desc_6: 'Click to view batch wallets',

  title_5: 'Exchange, transfer, etc. Sign with PIN code',
  exchange_desc_1:
    'Using the KeyPal hardware wallet to connect to the TokenPocket App, perform the following operations:',
  exchange_desc_2:
    'In the below pictures, the characters "*" shown in the rightmost picture of the first row are corresponding to the digital numbers shown in the second picture of the second row. You only need to follow the PIN code set up originally when the wallet was created according to the digital numbers shown in the KeyPal device.',
  exchange_desc_3:
    'Input on the client side according to the position where KeyPal displays the number',

  tutorial_title_1: 'How to verify your KeyPal device?',
  tutorial_desc_1: 'Enter the device management and enter the device safety.',
  tutorial_title_2: 'How to verify your address is belong to KeyPal?',
  tutorial_desc_2:
    "Connecting KeyPal with TokenPocket, then select 'receive' 'test' by step",
  tutorial_title_3: 'How to receive token by KeyPal?',
  tutorial_desc_3: "Connecting KeyPal with TokenPocket, and select 'receive'",
  tutorial_title_4: 'How to send token by KeyPal?',
  tutorial_desc_4:
    "Connecting KeyPal with TokenPocket, select 'send' and 'transfer'",
  tutorial_title_5:
    'How to enter the PIN code when you are making the transaction?',
  tutorial_desc_5: '',
};
