export default {
  buy_desc_1: '支持超過1000種公鏈',
  buy_desc_2: '包括 BTC、ETH、TRON, BSC和 EVM 兼容的公鏈',
  buy_desc_3: '一台KeyPal，管理所有數字資產',

  features: '產品特點',
  features_title_1: 'MCU+SE安全雙芯品',
  features_desc_1: '軍工級芯片，保障資產安全',
  features_title_2: '2英寸LCD全彩大屏幕',
  features_desc_2: '盡覽交易細節，所見即所簽',
  features_title_3: '採用藍牙安全連接方式',
  features_desc_3: '採用脫敏通訊技術',
  features_title_4: '物理、加密多種保護',
  features_desc_4: '不觸網, PIN碼, 校驗連接',
  color: '顏色',
  free_shipping: '免運費',

  break_down_desc_1: '向左按鍵',
  break_down_desc_2: '在顯示屏中向左移動或返回上一頁',
  break_down_desc_3: '確認按鍵',
  break_down_desc_4: '確認操作或進入菜單頁面，長按3秒開機或關機',
  break_down_desc_5: '向上按鍵',
  break_down_desc_6: '在顯示中向上移動',
  break_down_desc_7: '向右按鍵',
  break_down_desc_8: '在顯示中向右移動或確認操作',
  break_down_desc_9: '向下按鍵',
  break_down_desc_10: '在顯示中向下移動',

  display_title: '顯示器',
  display_desc: '2英寸，LCD全彩屏幕',
  battery_title: '電池',
  battery_desc: '100毫安',
  materials_title: '材質',
  materials_desc: '鋁合金',
  dual_chip_title: '雙芯片',
  dual_chip_desc: 'MCU + SE 安全雙芯片',

  easy_and_safe_title: '易用、安全',
  easy_and_safe_desc: '簡單、安全、易使用，全方位保護您的數字資產安全',
  easy_and_safe_list_1: '雙芯片',
  easy_and_safe_list_2: 'MCU + SE 安全雙芯片',
  easy_and_safe_list_3: '簡易連接',
  easy_and_safe_list_4: '藍牙 和 Type-C',
  easy_and_safe_list_5: '2英寸全彩屏幕',
  easy_and_safe_list_6: '所見即所簽',

  product_specifications: '產品規格',
  detail_center: 'USB Type-C',
  detail_left_1: '鋁合金堅硬外殼，保護錢包不受損傷',
  detail_left_2: '向左按鍵',
  detail_left_3: '向下按鍵',
  detail_right_1: '2英寸大彩屏，盡覽交易細節',
  detail_right_2: '向上按鍵',
  detail_right_3: '向右按鍵',
  detail_right_4: '確認按鍵',

  keypal_card_1: '尺寸',
  keypal_card_label_1: '91mm*55mm*6.7mm',
  keypal_card_2: '電池',
  keypal_card_label_2: '100毫安',
  keypal_card_3: '連接方式',
  keypal_card_label_3: '藍牙和Type-C',
  keypal_card_4: '材質',
  keypal_card_label_4: '鋁合金',
  keypal_card_5: '重量',
  keypal_card_label_5: '53g',
  keypal_card_6: '顯示',
  keypal_card_label_6: '2英寸全彩大屏幕',
  keypal_card_7: '雙芯片',
  keypal_card_label_7: 'MCU+SE安全雙芯片',
  keypal_card_8: '證書',
  keypal_card_label_8: 'DOE CEC BEC CE FCC RoHS',

  package: '包裝',
  package_line_1: '1 個硬件錢包',
  package_line_2: '1 條USB-Type-C 數據線',
  package_line_3: '2 張助記詞卡',
  package_line_4: '1 張貼紙',

  keypal_plus: {
    larger_battery: '電池容量大2倍',
    keypal_card_1: '尺寸',
    keypal_card_label_1: '91mm*55mm*9.0mm',
    keypal_card_2: '電池',
    keypal_card_label_2: '300毫安',
    keypal_card_3: '連接方式',
    keypal_card_label_3: '藍牙和Type-C',
    keypal_card_4: '材質',
    keypal_card_label_4: '鋁合金',
    keypal_card_5: '重量',
    keypal_card_label_5: '63g',
    keypal_card_6: '顯示',
    keypal_card_label_6: '2英寸全彩大屏幕',
    keypal_card_7: '雙芯片',
    keypal_card_label_7: 'MCU+SE安全雙芯片',
    keypal_card_8: '證書',
    keypal_card_label_8: 'DOE CEC BEC CE FCC RoHS',

    color: '顏色',
    color_desc: '磨砂黑, 磨砂銀, 香檳金',
    battery: '電池',
    battery_desc: '300 毫安',
    left: '向左',
    down: '向下',
    right: '向右',
    up: '向上',
    ok: '確認',
    center: 'USB Type-C',
    left_1: '向左按鍵',
    left_2: '在顯示屏中向左移動或返回上一頁',
    left_3: '向下按鍵',
    left_4: '在顯示中向下移動',
    right_1: '向上按鍵',
    right_2: '在顯示中向上移動',
    right_3: '向右按鍵',
    right_4: '在顯示中向右移動或確認操作',
    right_5: '確認按鍵',
    right_6: '確認操作或進入菜單頁面，長按3秒開機或關機',
    mobile_center: 'Type-C',
    mobile_center_2: '用於充電和固件升級',
  },
};
