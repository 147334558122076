export default {
  text_1_1: '教學',

  text_2_1: '備份教學',
  text_2_2: '導入教學',
  text_2_3: '進階教學(支付功能)',

  text_3_1: '创建钱包',
  text_3_1_1: '1. 選擇建立錢包後，進入備份流程',

  text_3_2: '備份流程',
  text_3_2_1:
    '備份的頁面，選擇KeyPal Card備份。 整個備份流程分為3步，（1）為您的KeyPal Card設定PIN碼；（2）將助記詞備份到您的KeyPal Card中；（3）驗證您的備份是否正確 （4）開始使用',
  text_3_2_2:
    '點選開始備份，需要您輸入2次PIN碼，以避免您錯誤輸入PIN碼，PIN用於保護備份安全，且用於卡片存取權限判斷。 請注意：PIN碼由您自己保管，請務必記得！',
  text_3_2_3:
    '設定好PIN碼後，依照指引將KeyPal Card放置在手機後置鏡頭處，此處為NFC晶片感應區域。 由於iOS設備在與KeyPal Card建立安全連結會消耗一點時間，因此可能出現數秒無反應，請放心，該現像是正常情況。',
  text_3_2_4:
    '感應完成且資料無誤後，則會將您的助記詞加密寫入到KeyPal Card中。 備份完成後，介面會提醒您備份完成。',
  text_3_2_5:
    '備份完成後，進入最後一步，備份驗證，我們十分建議您進行備份驗證！',

  text_3_3: '驗證流程',
  text_3_3_1:
    '根據驗證指引，您需先輸入已設定好的KeyPal Card的PIN碼，輸入完成後，依照指引，將KeyPal Card放置在後置相機附近，進行NFC感應',
  text_3_3_2:
    '驗證成功，則會進入以下提示。 為了避免助記詞洩露，因此會進行模糊化，您可以根據提示，點擊查看助記詞是否都一致。',
  text_3_3_3:
    '結束驗證後，您可以選擇”下一步“，直接進入使用；為了增加您使用KeyPal Card的場景以及安全性，我們還提供了KeyPal Card的 ”支付功能“，您可以前往”高級功能教程“查看。',

  text_3_4: '注意事項',
  text_3_4_1: '1. TokenPocket不會儲存您的PIN碼，請您務必自行做好記憶和備份',
  text_3_4_2:
    '2. 每個KeyPal Card的PIN碼都可以不一樣，您在使用過程中，連續錯誤輸入10次PIN碼後，您的KeyPal Card資料會被完全清楚！',
  text_3_4_3: '3. 使用KeyPal Card進行備份都建議您使用新Card進行備份',
  text_3_4_4:
    '4. KeyPal Card進行重設會導致資料被全部清除且無法恢復，請務必了解該內容！',
  text_3_4_5:
    '5. 您的KeyPal Card在使用iOS進行備份、使用過程中，可能會出現較慢的情況，該情況屬於正常現象，請您耐心等待',

  text_4_1: '導入教學',
  text_4_2_1:
    '當您有已備份的KeyPal Card，您可以將KeyPal Card中備份的錢包匯入到TokenPocket中進行使用。 KeyPal Card導入可分為單網咯導入和多網路方式導入。 這裡我們主要講解多網路導入方式，單一網路導入的方式相似。',
  text_4_3_1:
    '1. 您選擇匯入錢包後，點選KeyPal硬體錢包，其後在彈跳窗中選擇KeyPal Card作為您的匯入方式。 如圖',
  text_4_3_2:
    '2. 選擇KeyPal Card導入之後，即可點選開始匯入，輸入您已有備份的KeyPal Card的PIN碼。',
  text_4_3_3:
    '3. 輸入完PIN碼後，會自動彈出開始掃描頁面，隨後您可以將KeyPal Card放置在後置攝像頭附近，您準備好後，即可點擊“開始掃描”，進行NFC感應，進入KeyPal Card讀取流程。 iOS裝置的匯入可能需要一點時間，中間請等待一下。',
  text_4_3_4:
    '4. 當助記詞讀取成功後，會對您進行提醒，您可選擇「下一步」進入下一個流程，進行錢包網路選擇與創建，隨後即可開始使用錢包。 為了增加您使用KeyPal Card的場景以及安全性，我們還提供了KeyPal Card的 ”支付功能“，您可以前往”高級功能教程“查看。',
  text_4_4: '注意事項',
  text_4_4_1:
    '1. 您的KeyPal Card在使用iOS進行備份、使用過程中，可能會出現較慢的情況，該情況屬於正常現象，請您耐心等待。',
  text_4_4_2:
    '2. 請您注意：在讀取您的KeyPal Card過程前，會要求您輸入PIN碼，為了確保您的KeyPal Card安全，PIN碼一旦連續錯誤10次，KeyPal Card將自動清除所有資料。 一旦正確，錯誤次數則清零。',
  text_4_4_3:
    '3. 使用“支付功能”，助記詞將不會在您的手機設備中保存，您每次需要進行交易時，都需要進行掃描您的KeyPal Card進行交易簽名。',

  text_5_1: '進階功能',
  text_5_2_1:
    'KeyPal Card的支付功能旨在增強您的KeyPal Card使用場景，同時讓您的助記詞、私鑰不再保存在您的手機裝置中，讓其保持離線且更安全。',
  text_5_3_1:
    '1. 建立或匯入的時候，進階選項中，您可以選擇不將助記詞或私鑰保留在您的裝置中；後續您需要進行交易的時候，則需要透過掃描KeyPal Card的方式進行交易簽名',
  text_5_3_2:
    '2. 當您發起轉帳或一筆DApp交易時，交易面板的底部會提示您」掃卡支付“，點擊之後進入掃卡支付流程',
  text_5_3_3:
    '3. 點擊」掃卡付款「後，您需要輸入KeyPal Card的PIN碼；以驗證您的身分。',
  text_5_3_4:
    '4. 輸入PIN碼之後，按照指引，放置您的KeyPal Card，進行卡片讀取並進行交易簽名，簽名結束後會將交易發送到區塊鏈上，完成交易。',
  text_5_4: '注意事項',
  text_5_4_1:
    '1. 請您注意：在讀取您的KeyPal Card過程前，會要求您輸入PIN碼，為了確保您的KeyPal Card安全，PIN碼一旦連續錯誤10次，KeyPal Card將自動清除所有資料。 一旦正確，錯誤次數則清零。',
  text_5_4_2:
    '2. 使用“支付功能”，助記詞將不會在您的手機設備中保存，您每次需要進行交易時，都需要進行掃描您的KeyPal Card進行交易簽名。',
};
