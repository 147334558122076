import home from './home';
import common from './common';
import navigation from './navigation';
import blockchain from './blockchain';
import firmwareUpgrade from './firmwareUpgrade';
import security from './security';
import academy from './academy';
import keypal from './keypal';
import safebox from './safebox';
import keypalCard from './keypalCard';
import keypalTutorial from './keypalTutorial';

export default {
  home,
  common,
  navigation,
  blockchain,
  firmwareUpgrade,
  security,
  academy,
  keypal,
  safebox,
  keypalCard,
  keypalTutorial,
};
