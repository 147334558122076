<template>
  <div class="software content">
    <div class="title">{{ $t('home.software.title') }}</div>
    <div class="software-wrap d-flex ai-center jc-between">
      <div class="connect-methods">
        <div class="products d-flex">
          <div
            class="func-mobile"
            :class="{
              'enter-func-mobile': isPlayConnectMobile,
            }"
          >
            <div class="func-mobile-filter"></div>
            <div class="grey" :class="{ enterGrey: isPlayConnectExtension }">
              <img
                class="mobile"
                src="../../assets/images/software/software-mobile.png"
              />
              <img
                class="frame-bg"
                v-if="frameIndex === 1"
                src="../../assets/images/software/frame/frame-bg.png"
              />
              <img class="frame" :src="frameImg" />
              <div class="transition-wrap" v-show="frameIndex < 5">
                <img
                  src="../../assets/images/software/frame/transition-bg.png"
                />
                <div class="transition-bg" :class="{ enter: isTransitionBg }">
                  <div class="bar"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="keypal-wrap">
            <div class="keypal-filter"></div>
            <div class="interface" v-show="isInterface"></div>
            <img class="keypal" src="../../assets/images/home/keypal-1.png" />
          </div>
          <img
            v-show="frameIndex > 1 && frameIndex < 6"
            class="bluetooth-2"
            src="../../assets/images/software/bluetooth-2.png"
          />
          <div
            class="extension-wrap"
            :class="{
              'enter-extension': isPlayConnectExtension,
            }"
          >
            <div class="extension-filter"></div>
            <img
              class="extension"
              :class="{ opacity: frameIndex > 1 && frameIndex !== 6 }"
              src="../../assets/images/software/extension.png"
            />
          </div>
          <img
            class="usb-2"
            v-show="isUSB"
            src="../../assets/images/software/USB-2.png"
          />
        </div>
        <div class="connect-btn d-flex ai-center jc-center">
          <img class="phone" src="../../assets/images/software/phone.png" />
          <img
            class="arrow-blue-1"
            src="../../assets/images/software/arrow-blue.png"
          />
          <div
            class="circleBox"
            @mouseenter="enterBluetooth"
            @mouseleave="leaveBluetooth"
            @click="enterBluetooth"
          >
            <div class="dot-1"></div>
            <div class="dot-2"></div>
            <div class="dot-3"></div>
            <img src="../../assets/images/software/bluetooth.png" />
          </div>
          <span class="text">{{ $t('home.software.connect') }}</span>
          <div class="circleBox" @mouseenter="enterUSB" @mouseleave="leaveUSB">
            <div class="dot-1"></div>
            <div class="dot-2"></div>
            <div class="dot-3"></div>
            <img src="../../assets/images/software/USB.png" />
          </div>
          <img
            class="arrow-blue-1 arrow-blue-2"
            src="../../assets/images/software/arrow-blue.png"
          />
          <img class="phone" src="../../assets/images/software/computer.png" />
          <!-- v-if="!isConnectExtension" -->
          <canvas ref="canvas" width="400" height="150"></canvas>
        </div>
      </div>
      <div class="featured">
        <div class="cross-platform">
          <div class="text">{{ $t('home.software.crossPlatform') }}</div>
          <div class="platform-list">
            <div class="ios-android d-flex">
              <PlatformItem
                v-for="(item, index) in platformList.slice(0, 2)"
                :key="index"
                :platform="item"
              />
              <!-- :ml="index > 0 ? '0.1rem' : ''" -->
            </div>
            <div class="other-platform d-flex">
              <PlatformItem
                v-for="(item, index) in platformList.slice(2)"
                :key="index"
                :platform="item"
              />
              <!-- :ml="index > 0 ? '0.1rem' : ''" -->
            </div>
          </div>
        </div>
        <div class="multi-chains">
          <div class="text">{{ $t('home.software.multiChains') }}</div>
          <div class="detail">
            <div class="ethereum d-flex ai-center">
              <img src="../../assets/images/software/multi-chains/eth.png" />
              <span class="text">Ethereum</span>
            </div>
            <div class="text">{{ $t('home.software.supportDesc') }}</div>
          </div>
          <div class="token-list">
            <div class="row d-flex">
              <PlatformItem
                v-for="(item, index) in tokenList.slice(0, 3)"
                :key="index"
                :platform="item"
                :ml="index > 0 ? '0.1rem' : ''"
              />
            </div>
            <div class="row d-flex">
              <PlatformItem
                v-for="(item, index) in tokenList.slice(3)"
                :key="index"
                :platform="item"
                :ml="index > 0 ? '0.1rem' : ''"
              />
            </div>
          </div>
          <div class="more d-flex ai-center" @click="goBlockchain">
            <span class="text">{{ $t('home.software.more') }}</span>
            <img src="../../assets/images/software/enter-blue.png" />
          </div>
        </div>
      </div>
      <div class="software-bg">
        <img src="../../assets/images/software/software-bg.png" />
      </div>
    </div>
  </div>
</template>

<script>
import PlatformItem from '../common/PlatformItem.vue'
import mobile from '../../mixins/mobile'

export default {
  components: { PlatformItem },
  mixins: [mobile],
  data() {
    return {
      platformList: [
        {
          logo: require('../../assets/images/software/featured/1.png'),
          name: 'iOS',
        },
        {
          logo: require('../../assets/images/software/featured/2.png'),
          name: 'Android',
        },
        {
          logo: require('../../assets/images/software/featured/3.png'),
          name: 'macOS',
        },
        {
          logo: require('../../assets/images/software/featured/4.png'),
          name: 'Windows',
        },
        {
          logo: require('../../assets/images/software/featured/5.png'),
          name: 'Linux',
        },
      ],
      tokenList: [
        {
          logo: require('../../assets/images/software/multi-chains/btc.png'),
          name: 'Bitcoin',
        },
        {
          logo: require('../../assets/images/software/multi-chains/BSC.png'),
          name: 'BSC',
        },
        {
          logo: require('../../assets/images/software/multi-chains/TRON.png'),
          name: 'TRON',
        },
        {
          logo: require('../../assets/images/software/multi-chains/MATIC.png'),
          name: 'Polygon',
        },
        {
          logo: require('../../assets/images/software/multi-chains/FTM.png'),
          name: 'Fantom',
        },
        {
          logo: require('../../assets/images/software/multi-chains/ARB.png'),
          name: 'Arbitrum',
        },
      ],
      frameIndex: 1,
      interId: undefined,
      timeoutId: undefined,
      requestAnimationFrameId: undefined,
      percent: 0,
      data1: {
        start: [20, 50],
        point: [0, 140],
        end: [100, 75],
        clearRect: {
          x: 0,
          y: 0,
          w: 100,
          h: 200,
        },
      },
      data2: {
        start: [99, 76],
        point: [165, 40],
        end: [230, 70],
        clearRect: {
          x: 100,
          y: 0,
          w: 130,
          h: 200,
        },
      },
      data3: {
        start: [230, 70],
        point: [380, 120],
        end: [265, 49],
        clearRect: {
          x: 230,
          y: 0,
          w: 200,
          h: 200,
        },
      },
      mobileData1: {
        start: [20, 50],
        point: [10, 110],
        end: [60, 70],
        clearRect: {
          x: 0,
          y: 0,
          w: 60,
          h: 200,
        },
      },
      mobileData2: {
        start: [59, 71],
        point: [95, 50],
        end: [140, 70],
        clearRect: {
          x: 59,
          y: 0,
          w: 70,
          h: 200,
        },
      },
      mobileData3: {
        start: [140, 70],
        point: [220, 120],
        end: [158, 60],
        clearRect: {
          x: 140,
          y: 0,
          w: 200,
          h: 200,
        },
      },
      lineCount: 5,
      isConnectMobile: true,
      isConnectExtension: true,
      isPlayConnectMobile: false,
      isPlayConnectExtension: false,
      isTransitionBg: false,
      ctx: null,
      isInterface: false,
      isUSB: false,
    }
  },
  computed: {
    frameImg() {
      return require(`../../assets/images/software/frame/frame-${this.frameIndex}.png`)
    },
    drawData1() {
      if (this.isMobile) {
        return this.mobileData1
      } else {
        return this.data1
      }
    },
    drawData2() {
      if (this.isMobile) {
        return this.mobileData2
      } else {
        return this.data2
      }
    },
    drawData3() {
      if (this.isMobile) {
        return this.mobileData3
      } else {
        return this.data3
      }
    },
    lineWidth() {
      if (this.isMobile) {
        return 2
      } else {
        return 3
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      const canvas = this.$refs.canvas
      if (canvas) {
        this.ctx = canvas.getContext('2d')
      }
      // this.init(this.ctx, this.data1)
    })
  },

  methods: {
    goBlockchain() {
      this.$router.push({ path: '/blockchain' })
    },
    enterBluetooth() {
      if (this.isConnectMobile) {
        this.isPlayConnectMobile = true
        this.isConnectExtension = false
        this.isConnectMobile = false
        this.isTransitionBg = true
        this.frameIndex = 1
        if (this.interId) {
          clearInterval(this.interId)
        }

        this.frameIndex++
        this.interId = setInterval(() => {
          if (this.frameIndex < 5) {
            this.frameIndex++
          }
          // 动画播放结束
          // if (this.frameIndex === 6) {
          //   this.isPlayConnectMobile = false
          //   this.isConnectMobile = true
          //   this.isConnectExtension = true
          //   this.isTransitionBg = false
          // }
        }, 500)
      }
    },
    // 鼠标离开硬件蓝牙连接手机端恢复原状
    leaveBluetooth() {
      this.isPlayConnectMobile = false
      this.isConnectMobile = true
      this.isConnectExtension = true
      this.isTransitionBg = false
      this.frameIndex = 6
      clearInterval(this.interId)
    },
    enterUSB() {
      if (this.isConnectExtension) {
        this.isInterface = true
        this.isPlayConnectExtension = true
        this.isConnectMobile = false
        this.isConnectExtension = false
        this.lineCount = 1
        this.ctx.clearRect(0, 0, 500, 150)
        if (!this.isMobile) {
          this.init(this.ctx, this.drawData1)
        } else {
          this.isInterface = false
        }
      }
    },
    // 鼠标离开硬件USB连接插件
    leaveUSB() {
      this.isConnectMobile = true
      this.isConnectExtension = true
      this.isPlayConnectExtension = false
      this.isInterface = false
      this.isUSB = false
      this.percent = 0
      this.ctx.clearRect(0, 0, 500, 150)
      clearTimeout(this.timeoutId)
      cancelAnimationFrame(this.requestAnimationFrameId)
    },
    init(ctx, data) {
      this.percent = 0
      this.draw(ctx, data)
    },
    draw(ctx, data) {
      ctx.clearRect(
        data.clearRect.x,
        data.clearRect.y,
        data.clearRect.w,
        data.clearRect.h
      )
      ctx.strokeStyle = '#000'
      ctx.lineWidth = this.lineWidth
      this.drawCurvePath(ctx, data.start, data.point, data.end, this.percent)
      this.percent += 2

      if (this.percent <= 100) {
        this.requestAnimationFrameId = requestAnimationFrame(() => {
          this.draw(ctx, data)
        })
      } else {
        this.lineCount++
        if (this.lineCount === 2) {
          this.init(ctx, this.drawData2)
        } else if (this.lineCount === 3) {
          this.init(ctx, this.drawData3)
        } else {
          this.isUSB = true
          // this.timeoutId = setTimeout(() => {
          //   this.isUSB = false
          //   this.isInterface = false
          //   this.isPlayConnectExtension = false
          //   this.isConnectMobile = true
          //   this.isConnectExtension = true
          //   this.ctx.clearRect(0, 0, 500, 150)
          // }, 1000)
        }
      }
    },
    drawCurvePath(ctx, start, point, end, percent) {
      ctx.beginPath()
      ctx.moveTo(start[0], start[1])
      for (var t = 0; t <= percent / 100; t += 0.005) {
        var x = this.quadraticBezier(start[0], point[0], end[0], t)
        var y = this.quadraticBezier(start[1], point[1], end[1], t)
        ctx.lineTo(x, y)
      }
      ctx.stroke()
    },
    quadraticBezier(p0, p1, p2, t) {
      var k = 1 - t
      return k * k * p0 + 2 * (1 - t) * t * p1 + t * t * p2 // 二次贝赛尔曲线方程
    },
  },
}
</script>

<style lang="scss" scoped>
.software {
  padding-top: 2rem;
  background: #fff;
  .text {
    font-size: 0.18rem;
    font-family: Inter-Regular;
    color: #101010;
  }
  .software-wrap {
    position: relative;
    margin-top: 0.8rem;
    .connect-methods {
      position: relative;
      .products {
        width: 518px;
        height: 335px;
        background: url('../../assets/images/software/connect-bg.png') no-repeat
          center center;
        background-position: center center;
        background-size: cover;
        .func-mobile {
          position: absolute;
          top: -45px;
          left: -10px;
          width: 150px;
          height: 311px;
          background: #fff;
          transition: opacity 0.5s linear, transform 0.5s linear;
          &.enter-func-mobile {
            transform: scale(1.2);
          }
          .func-mobile-filter {
            position: absolute;
            top: 50px;
            left: 90px;
            width: 105px;
            height: 275px;
            opacity: 0.08;
            background: #000000;
            border-radius: 0.18rem;
            filter: blur(9.35px);
          }
          .grey {
            height: 100%;
            &.enterGrey {
              opacity: 0.5;
            }
          }
          .mobile {
            position: absolute;
            width: 1.5rem;
            width: 150px;
            top: 0;
            left: 0;
            z-index: 10;
          }
          .frame-bg {
            width: 135px;
            z-index: 5;
            position: absolute;
            top: 0.1rem;
            top: 10px;
            left: 50%;
            transform: translateX(-50%);
          }
          .frame {
            width: 139px;
            top: 8px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 0;
          }
          .transition-wrap {
            position: absolute;
            z-index: 0;
            top: 67px;
            left: 50%;
            width: 84px;
            transform: translateX(-50%);
            img {
              width: 84px;
            }
            @keyframes heightAni {
              0% {
                height: 0;
              }
              49% {
                height: 80px;
              }
              50% {
                height: 0;
              }
              100% {
                height: 80px;
              }
            }
            .transition-bg {
              position: absolute;
              top: 2px;
              left: 50%;
              transform: translateX(-50%);
              width: 79px;
              height: 0;
              border-radius: 0.02rem;
              background: linear-gradient(
                0deg,
                #0dc5fe,
                rgba(13, 197, 254, 0.2)
              );
              .bar {
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 0;
                background: #0dc5fe;
              }
              &.enter {
                animation: heightAni 1.5s linear;
                .bar {
                  height: 2px;
                }
              }
            }
          }
        }
        img {
          position: absolute;
        }
        .mobile {
          width: 150px;
          top: -45px;
          left: -10px;
        }
        .keypal-wrap {
          position: absolute;
          width: 154px;
          height: 257px;
          left: 200px;
          top: 45px;
          .interface {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -16px;
            width: 22px;
            height: 16px;
            background: #000000;
          }
          .keypal-filter {
            position: absolute;
            top: 30px;
            left: 50px;
            width: 139px;
            height: 256px;
            opacity: 0.22;
            background: #000000;
            border-radius: 0.18rem;
            filter: blur(9.35px);
          }
          .keypal {
            width: 154px;
          }
        }
        .bluetooth-2 {
          width: 105px;
          left: 115px;
          top: 70px;
        }
        .extension-wrap {
          position: absolute;
          background: #fff;
          width: 136px;
          height: 231px;
          top: -30px;
          right: -25px;
          transition: opacity 0.5s linear, transform 0.5s linear;
          border-radius: 0.12rem;
          &.enter-extension {
            transform: scale(1.2);
          }
          .extension-filter {
            position: absolute;
            top: 60px;
            left: -40px;
            width: 122px;
            height: 219px;
            opacity: 0.08;
            background: #000000;
            border-radius: 0.18rem;
            filter: blur(9.35px);
          }
          .extension {
            width: 136px;
            &.opacity {
              opacity: 0.5;
            }
          }
        }
        .usb-2 {
          position: absolute;
          right: -8px;
          top: 315px;
          width: 25px;
        }
      }
      .connect-btn {
        .circleBox {
          position: relative;
          width: 1.04rem;
          height: 1.04rem;
          cursor: pointer;
          @for $i from 1 through 3 {
            .dot-#{$i} {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              margin: auto;
              width: 0.12rem;
              height: 0.12rem;
              border-radius: 50%;
              animation: ripple 2s ease-out #{$i * 0.5}s infinite;
            }
          }

          @keyframes ripple {
            0% {
              transform: scale(0, 0);
              opacity: 1;
            }
            100% {
              opacity: 0;
              transform: scale(8, 8);
              background: #2980fe;
            }
          }
          img {
            width: 0.48rem;
            height: 0.48rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        canvas {
          display: block;
          position: absolute;
          // background: pink;
          left: 257px;
          top: 268px;
          pointer-events: none;
          // opacity: 0.3;
        }
        margin-top: 0.3rem;
        .arrow-blue-1 {
          margin-left: 0.1rem;
          width: 0.16rem;
        }
        .arrow-blue-2 {
          transform: rotate(180deg);
          margin-left: 0;
          margin-right: 0.1rem;
        }
        .phone {
          width: 0.32rem;
        }
        span {
          font-size: 0.16rem;
          font-weight: 700;
          text-align: center;
          color: #333333;
          line-height: 0.2rem;
          max-width: 1rem;
        }
      }
    }
    .featured {
      .cross-platform {
        .platform-list {
          .ios-android {
            margin-top: 0.2rem;
          }
          .other-platform {
            margin-top: 0.1rem;
          }
        }
      }
      .multi-chains {
        margin-top: 0.4rem;
        .detail {
          margin-top: 0.2rem;
          width: 4.34rem;
          padding: 0.12rem 0.2rem;
          background: #fafafa;
          border-radius: 0.12rem;
          .ethereum {
            img {
              width: 0.2rem;
            }
            span {
              margin-left: 0.06rem;
              margin-top: 0;
              font-size: 0.12rem;
              font-family: Inter-Regular;
              color: #101010;
            }
          }
          .text {
            margin-top: 0.05rem;
            font-size: 0.12rem;
            font-family: Inter-Regular;
            color: #444444;
          }
        }
        .token-list {
          .row {
            margin-top: 0.1rem;
          }
        }
        .more {
          margin-top: 0.22rem;
          cursor: pointer;
          // border: 0.01rem dashed #000;
          width: fit-content;
          // border-radius: 0.12rem;
          // padding: 0.05rem 0.2rem;
          span {
            font-size: 0.12rem;
            color: #2980fe;
          }
          img {
            width: 0.12rem;
          }
        }
        // .more:hover {
        //   background: #2980fe;
        //   border: 0.01rem dashed #2980fe;
        //   span {
        //     color: #fff;
        //   }
        // }
      }
    }
    .software-bg {
      position: absolute;
      top: -1.8rem;
      left: -0.15rem;
      z-index: 100;
      img {
        width: 1.16rem;
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .software {
    .software-wrap {
      flex-direction: column;
      margin-top: 1rem;
      .connect-methods {
        .connect-btn {
          .bluetooth {
            // pointer-events: none;
          }
        }
      }
      .featured {
        margin-top: 0.5rem;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .software {
    .text {
      font-size: 0.26rem;
    }
    .software-wrap {
      .connect-methods {
        width: 100%;
        .products {
          width: 5.6rem;
          height: 3.6rem;
          margin: 0 auto;
          .func-mobile {
            width: 1.64rem;
            height: 3.38rem;
            top: -0.5rem;
            left: 0.3rem;
            .func-mobile-filter {
              width: 1.2rem;
              height: 3rem;
              left: 0.8rem;
              top: 0.8rem;
            }
            .mobile {
              width: 1.66rem;
            }
            .frame-bg {
              width: 1.53rem;
            }
            .frame {
              width: 1.53rem;
            }
            .transition-wrap {
              top: 0.8rem;
              width: 0.84rem;
              @keyframes heightAni {
                0% {
                  height: 0;
                }
                49% {
                  height: 0.8rem;
                }
                50% {
                  height: 0;
                }
                100% {
                  height: 0.8rem;
                }
              }
              img {
                width: 0.84rem;
              }
              .transition-bg {
                width: 0.8rem;
                height: 0.8rem;
              }
            }
          }
          .keypal-wrap {
            width: 1.66rem;
            height: 2.78rem;
            left: 50%;
            top: 0.3rem;
            transform: translateX(-50%);
            .keypal {
              width: 1.66rem;
            }
            .keypal-filter {
              top: 0.5rem;
              left: 0.6rem;
              width: 1.4rem;
              height: 2.5rem;
            }
            .interface {
              width: 0.22rem;
              height: 0.16rem;
              bottom: -0.14rem;
            }
          }
          .extension-wrap {
            width: 1.48rem;
            height: 2.51rem;
            left: auto;
            right: 0.4rem;
            top: -0.3rem;
            .extension {
              width: 1.48rem;
              height: 2.51rem;
            }
            .extension-filter {
              top: 1rem;
              left: -0.4rem;
              width: 1.2rem;
              height: 2rem;
            }
          }
          .bluetooth-2 {
            width: 0.52rem;
            left: 2.05rem;
            top: 1rem;
          }
          .usb-2 {
            width: 0.25rem;
            right: 0.52rem;
            top: 3.4rem;
          }
        }
        .connect-btn {
          canvas {
            display: none;
            left: 3rem;
            top: 2.16rem;
          }
          .phone {
            width: 0.4rem;
          }
          .arrow-blue-1 {
            width: 0.22rem;
          }
          .bluetooth {
            width: 1.3rem;
          }
          span {
            font-size: 0.22rem;
            line-height: 0.26rem;
          }
        }
      }
      .featured {
        .platform-list {
          margin-top: 0.3rem;
        }
        .multi-chains {
          .detail {
            width: 100%;
            padding: 0.21rem 0.32rem;
            .ethereum {
              img {
                width: 0.32rem;
              }
              span {
                font-size: 0.2rem;
              }
            }
            .text {
              font-size: 0.18rem;
              line-height: 0.25rem;
            }
          }
          .more {
            margin-top: 0.3rem;
            span {
              font-size: 0.2rem;
            }
            img {
              width: 0.2rem;
            }
          }
        }
      }
    }
  }
}
</style>
