export default {
  shopNow: 'Buy Now',
  preSale: 'Preselling',
  header: {
    title: 'Your Secure Crypto Hardware Wallet',
    subtitle: 'Store and manage your crypto safely and easily',
  },
  introduce: {
    title: 'You Deserve a Better Hardware Wallet',
    subtitle: 'Secure. Reliable. Easy.',
    desc_1: 'Solid shell to protect the wallet from damage',
    desc_2: 'Military grade chips to guarantee the security',
    desc_3: 'Devices are identified while being used',
    desc_4: 'Keys are generated offline and stored locally',
    desc_5: 'You sign what you see',
    desc_6: 'PIN code supported',
  },
  function: {
    title: 'An All-in-One Crypto Hardware Wallet',
    subtitle: 'One wallet to meet all your crypto needs',
    func_title_1: 'Secure & Store',
    func_desc_1:
      "Generate your keys and store it in your KeyPal with the world's safest chips. 1000+ chains supported. No one can access it but you.",
    func_title_2: 'Buy & Grow',
    func_desc_2:
      'Buy Bitcoin, Ether and other cryptocurrencies in the simple, safe and smart way. You can grow it without leaving the wallet.',
    func_title_3: 'Exchange & Cross chain',
    func_desc_3:
      'Exchange your cryptocurrencies in seconds among the supported chains, you can easily cross your assets from one to another chain.',
    func_title_4: 'Unlock the possibilities of crypto',
    func_desc_4:
      'Safely to access all the decentralized applications — DeFis, DApps, GameFis and NFTs.',
  },
  software: {
    title: 'KeyPal & TokenPocket',
    connect: 'Connect Methods',
    crossPlatform: 'Cross-platform featured',
    multiChains: 'Multi-chains supported',
    supportDesc:
      '(Support all EVM compatible chains, like BNB Smart Chain, Polygon, ETC, Arbitrum)',
    more: 'More',
  },
  specification: {
    title: 'Specifications',
    card_1: 'Core',
    option_label_1_1: 'Chips',
    option_desc_1_1: 'MCU + SE',
    option_label_1_2: 'Certifications and Standards',
    option_desc_1_2: 'DOE, CEC, BEC, CE, FCC & RoHS',

    card_2: 'Battery and Connector',
    option_label_2_1: 'Battery',
    option_desc_2_1: '300 mAh',
    option_label_2_2: 'Connector',
    option_desc_2_2: 'USB Type-C',

    card_3: 'Size and Weight',
    option_label_3_1: 'Size',
    option_desc_3_1: '91 x 55 x 9.0(mm)',
    option_label_3_2: 'Weight',
    option_desc_3_2: '63(g)',

    card_4: 'Display',
    option_label_4_1: 'Screen',
    option_desc_4_1: '2-inch',
    option_label_4_2: 'Display',
    option_desc_4_2: 'LCD, Color',
    option_label_4_3: 'Resolution',
    option_desc_4_3: '320 x 480',

    card_5: 'Compatibility',
    option_desc_5_1: 'iOS, Android, macOS, Windows, Linux',

    card_6: 'Connect Methods',
    option_desc_6_1: 'Bluetooth & USB Type-C',

    card_7: 'Materials',
    option_desc_7_1: 'Aluminum alloy',

    card_8: 'Colors',
    option_desc_8_1: 'Black, Silver, Golden',

    btnTitle: 'Ready to use the KeyPal Wallet',
    btnSubTitle: 'Your secure crypto hardware wallet',
  },
}
