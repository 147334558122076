<template>
  <div class="platform-item d-flex ai-center jc-center">
    <!-- :style="{ 'margin-left': ml }" -->
    <img :src="platform.logo" />
    <span class="text">{{ platform.name }}</span>
  </div>
</template>

<script>
export default {
  props: {
    platform: {
      type: Object,
      require: true,
    },
    ml: {
      type: String,
    },
  },
}
</script>

<style lang="scss" scoped>
.platform-item {
  width: 1.38rem;
  padding: 0.14rem 0;
  background: #fafafa;
  border-radius: 0.12rem;
  &:nth-child(n + 2) {
    margin-left: 0.1rem;
  }
  img {
    width: 0.2rem;
  }
  span {
    margin-left: 0.1rem;
    font-size: 0.12rem;
    font-family: Inter-Regular;
    color: #444444;
  }
}
@media screen and (max-width: 767px) {
  .platform-item {
    width: 2.18rem;
    padding: 0.22rem 0;
    &:nth-child(n + 2) {
      margin-left: 0.16rem;
    }
    img {
      width: 0.32rem;
    }
    span {
      margin-left: 0.1rem;
      font-size: 0.2rem;
    }
  }
}
</style>
