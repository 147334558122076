export default {
  title: 'KeyPal支持的区块链列表（1000+）',
  getKeypal: '拥有 KeyPal',
  comingSoon: '支持中',
  list: {
    title: 'Blockchain',
    detail: '详情',
    urlName: '链接',
  },
  keypal: {
    youzan: '有赞',
    tb: '淘宝',
    youzanDesc: '使用支付宝/微信扫码进行购买',
    tbDesc: '使用支付宝/淘宝扫码进行购买',
    urlName: '访问网页版',
    buyWay: '请选择支付购买方式',
    cancel: '取消',
  },
}
