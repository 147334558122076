<template>
  <div class="specification">
    <div class="content">
      <div class="title">{{ $t('home.specification.title') }}</div>
      <div class="specification-wrap d-flex ai-center jc-between">
        <div class="options d-flex">
          <div class="row">
            <OptionCard
              v-for="(card, index) in optionCardLeftList"
              :key="index"
              :card="card"
            />
          </div>
          <div class="row row2">
            <OptionCard
              v-for="(card, index) in optionCardRightList"
              :key="index"
              :card="card"
              @onColor="onColor"
            />
          </div>
        </div>
        <div class="renderings d-flex ai-center jc-center">
          <!-- <img
            :class="{
              'color-1': colorIndex !== 0,
            }"
            :src="renderingsImg"
          /> -->
          <img
            v-show="colorIndex === 0"
            src="../../assets/images/specification/keypal-color-0.png"
          />
          <img
            v-show="colorIndex === 1"
            src="../../assets/images/specification/keypal-color-1.png"
          />
          <img
            v-show="colorIndex === 2"
            src="../../assets/images/specification/keypal-color-2.png"
          />
          <img
            v-show="colorIndex === 3"
            src="../../assets/images/specification/keypal-color-3.png"
          />
        </div>
      </div>
      <div class="show-now-button d-flex ai-center jc-end">
        <div class="detail-wrap">
          <div class="title text">{{ $t('home.specification.btnTitle') }}</div>
          <div class="desc text">
            {{ $t('home.specification.btnSubTitle') }}
          </div>
        </div>
        <div class="button d-flex ai-center jc-center" @click="goBuyKeyPal">
          <img src="../../assets/images/home/shop-card-2.png" />
          <span class="text">{{ $t('home.shopNow') }}</span>
        </div>
      </div>
      <div
        class="show-now-button show-now-button-mobile d-flex ai-center jc-end"
      >
        <div class="detail-wrap">
          <div class="title text">{{ $t('home.specification.btnTitle') }}</div>
          <div class="desc text">
            {{ $t('home.specification.btnSubTitle') }}
          </div>
        </div>
        <div class="button d-flex ai-center jc-center" @click="goBuyKeyPal">
          <img src="../../assets/images/home/shop-card-2.png" />
          <span class="text">{{ $t('home.shopNow') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OptionCard from '../common/OptionCard.vue'
export default {
  components: { OptionCard },
  data() {
    return {
      colorIndex: 0,
    }
  },
  computed: {
    optionCardLeftList() {
      return [
        {
          title: this.$t('home.specification.card_1'),
          option: [
            {
              icon: require('../../assets/images/specification/card-1-1.png'),
              label: this.$t('home.specification.option_label_1_1'),
              desc: this.$t('home.specification.option_desc_1_1'),
            },
            {
              icon: require('../../assets/images/specification/card-1-2.png'),
              label: this.$t('home.specification.option_label_1_2'),
              desc: this.$t('home.specification.option_desc_1_2'),
            },
          ],
        },
        {
          title: this.$t('home.specification.card_2'),
          option: [
            {
              icon: require('../../assets/images/specification/card-2-1.png'),
              label: this.$t('home.specification.option_label_2_1'),
              desc: this.$t('home.specification.option_desc_2_1'),
            },
            {
              icon: require('../../assets/images/specification/card-2-2.png'),
              label: this.$t('home.specification.option_label_2_2'),
              desc: this.$t('home.specification.option_desc_2_2'),
            },
          ],
        },
        {
          title: this.$t('home.specification.card_3'),
          option: [
            {
              icon: require('../../assets/images/specification/card-3-1.png'),
              label: this.$t('home.specification.option_label_3_1'),
              desc: this.$t('home.specification.option_desc_3_1'),
            },
            {
              icon: require('../../assets/images/specification/card-3-2.png'),
              label: this.$t('home.specification.option_label_3_2'),
              desc: this.$t('home.specification.option_desc_3_2'),
            },
          ],
        },
      ]
    },
    optionCardRightList() {
      return [
        {
          title: this.$t('home.specification.card_4'),
          disPlay: true,
          option: [
            {
              icon: require('../../assets/images/specification/card-4-1.png'),
              label: this.$t('home.specification.option_label_4_1'),
              desc: this.$t('home.specification.option_desc_4_1'),
            },
            {
              icon: require('../../assets/images/specification/card-4-2.png'),
              label: this.$t('home.specification.option_label_4_2'),
              desc: this.$t('home.specification.option_desc_4_2'),
            },
            {
              icon: require('../../assets/images/specification/card-4-3.png'),
              label: this.$t('home.specification.option_label_4_3'),
              desc: this.$t('home.specification.option_desc_4_3'),
            },
          ],
        },
        {
          title: this.$t('home.specification.card_5'),
          noIcon: true,
          option: [
            {
              desc: this.$t('home.specification.option_desc_5_1'),
            },
          ],
        },
        {
          title: this.$t('home.specification.card_6'),
          noIcon: true,
          option: [
            {
              desc: this.$t('home.specification.option_desc_6_1'),
            },
          ],
        },
        {
          title: this.$t('home.specification.card_7'),
          noIcon: true,
          option: [
            {
              desc: this.$t('home.specification.option_desc_7_1'),
            },
          ],
        },
        {
          title: this.$t('home.specification.card_8'),
          noIcon: true,
          option: [
            {
              desc: this.$t('home.specification.option_desc_8_1'),
            },
          ],
          colors: [0, 1, 2],
        },
      ]
    },
  },
  methods: {
    goBuyKeyPal() {
      if (this.$i18n.locale === 'zh') {
        window.open('https://shop95838799.m.youzan.com/v2/showcase/homepage?alias=sptfzUCg0j')
      } else {
        window.open('https://www.keypal.store/products/keypal-hardware-wallet-plus')
      }
    },
    onColor(index) {
      this.colorIndex = index
    },
  },
}
</script>

<style lang="scss" scoped>
.specification {
  margin-top: 1.5rem;
  background: #151515;
  padding: 0.9rem 0;
  .title {
    color: #ffffff;
  }
  .specification-wrap {
    margin-top: 0.7rem;
    .options {
      .row2 {
        margin-left: 0.45rem;
      }
    }
    .renderings {
      width: 5.04rem;
      height: 4.87rem;
      font-size: 0;
      img {
        width: 5.04rem;
      }
    }
  }
  .show-now-button {
    margin-top: 0.78rem;
    max-width: 12rem;
    height: 1.98rem;
    // background: rgba(41, 128, 254, 0.2);
    border: 0.01rem solid #2980fe;
    border-radius: 0.24rem;
    background: url('../../assets/images/specification/btn-bg.png') no-repeat
      center center;
    background-position: center center;
    background-size: cover;
    .detail-wrap {
      .desc {
        margin-top: 0.2rem;
        text-align: center;
        font-size: 0.2rem;
        font-weight: 400;
        color: #ffffff;
        line-height: 0.24rem;
      }
    }
    .button {
      margin-left: 0.6rem;
      margin-right: 0.48rem;
      padding: 0.14rem 0.5rem;
      border: 0.01rem solid #ffffff;
      border-radius: 0.4rem;
      cursor: pointer;
      span {
        margin-left: 0.06rem;
        font-size: 0.18rem;
        color: #ffffff;
      }
      img {
        width: 0.26rem;
      }
    }
    .button:hover {
      background: #2980fe;
      border: 0.01rem solid #2980fe;
    }
  }
  .show-now-button-mobile {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .specification {
    .specification-wrap {
      flex-direction: column;
      .options {
        position: relative;
        margin-top: 1.54rem;
        order: 2;
        .row2 {
          margin-left: 0.7rem;
        }
      }
      .renderings {
        width: 100%;
        height: 5rem;
        order: 1;
        display: flex;
        justify-content: center;
        // img {
        //   // width: 80%;
        //   // height: ;
        // }
      }
    }
    .show-now-button {
      display: none;
    }
    .show-now-button-mobile {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      height: 2.38rem;
      background: url('../../assets/images/specification/btn-bg-mobile.png')
        no-repeat center center;
      background-position: center center;
      background-size: cover;
      .detail-wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .title {
          font-size: 0.3rem;
          max-width: 3.5rem;
          text-align: right;
          padding: 0 0.3rem 0 0;
          line-height: 0.36rem;
        }
        .desc {
          margin-top: 0.12rem;
          padding-right: 0.3rem;
          font-size: 0.2rem;
        }
      }
      .button {
        margin-left: 0;
        margin-right: 0.3rem;
        margin: 0.16rem 0.3rem 0 0;
        max-width: fit-content;
        background: #2980fe;
        border: none;
        img {
          width: 0.3rem;
        }
        span {
          font-size: 0.18rem;
        }
      }
    }
  }
}
</style>
