export default {
  title_1: '操作说明',
  operation_desc_1: '铝合金外壳，更好的保护硬件钱包',
  operation_desc_2: '向左按键',
  operation_desc_3: '在显示屏中向左移动选择或返回上一页',
  operation_desc_4: '向下按键',
  operation_desc_5: '在显示屏中向下移动选择',
  operation_desc_6: '2英寸超大屏，所见即所签',
  operation_desc_7: '向上按键',
  operation_desc_8: '在显示屏中向上移动选择',
  operation_desc_9: '向右按键',
  operation_desc_10: '在显示屏中向右移动选择或确认当前操作',
  operation_desc_11: '确认按键',
  operation_desc_12: '确认操作或进入菜单页面，长按3秒左右开机或关机',
  operation_desc_13: 'Type-C接口',
  operation_desc_14: '用于充电和固件升级',

  title_2: '如何开始使用 KeyPal',
  get_start_desc_1: '初始化',
  get_start_desc_2: '长按 "',
  get_start_desc_3: '"确认按键3秒左右开机，它将显示 KP_XXX 未激活。',
  get_start_desc_4: '激活步骤（创建钱包）:',
  get_start_desc_5: '1. 点击',
  get_start_desc_6: '进入主菜单。',
  get_start_desc_7: '2. 按向下按键',
  get_start_desc_8: '然后选择"创建钱包"。',
  get_start_desc_9:
    '3. 按顺序备份并验证助记词，并设置PIN码，即可成功激活设备。',

  get_start_desc_10: '请使用纸笔或KEYPAL助记词密盒记录保存助记词',
  get_start_desc_11: '请按顺序记录助记词并验证',

  title_3: '如何将 KeyPal 与 TokenPocket 连接',
  connect_desc_1: '1. 确保KeyPal的蓝牙已开启：选择【设置】-【蓝牙】。',
  connect_desc_2: '2. 在 TokenPocket 中，点击钱包 "',
  connect_desc_3: '" 在“选择网络”页面点击KeyPal硬件钱包。',
  connect_desc_4:
    '3. 选择添加设备，TokenPocket会开始搜索设备，选择当前蓝牙名称对应的设备。',
  connect_desc_4_2:
    '（温馨提示：蓝牙名称可以在【关于】-【设备信息】中查看，如果客户端找不到对应的蓝牙设备，请重启硬件设备，重复连接步骤。',
  connect_desc_5:
    '设备连接成功后，TokenPocket资产页面右上角会成功显示KeyPal蓝牙连接图标。',
  connect_desc_6: '连接成功',

  title_4: '批量添加',
  batch_desc_1: '1. 使用KeyPal通过蓝牙连接TokenPocket APP。',
  batch_desc_2: '2. 点击App右上角带有蓝牙连接标志的硬件钱包图标。',
  batch_desc_3: '3. 进入“KeyPal管理”，点击“添加钱包”。',
  batch_desc_4: '4. 进入页面，点击右上角的“批量添加”， 即可完成。',
  batch_desc_5: 'KeyPal如何连接TokenPocket，请查看上一页',
  batch_desc_6: '点击查看批量钱包',

  title_5: '兑换、转账等，使用PIN码进行确认签名',
  exchange_desc_1: '使用KeyPal硬件钱包连接TokenPocket App，执行以下操作：',
  exchange_desc_2:
    '在下图中，第一行最右边图片中的“*”字符对应于第二行第二张图片中显示的数字。 您只需要按照KeyPal硬件钱包设备中显示的数字号码创建钱包时最初设置的PIN码即可。',
  exchange_desc_3: '客户端根据KeyPal设备显示数字的位置输入',

  tutorial_title_1: '如何验证您的 KeyPal硬件钱包设备？',
  tutorial_desc_1: '进入设备管理，进入设备安全。',
  tutorial_title_2: '如何验证您的地址是否属于KeyPal？',
  tutorial_desc_2:
    '将 KeyPal硬件钱包 通过蓝牙连接TokenPocket，然后在主界面选择收款-校验。',
  tutorial_title_3: '如何使用KeyPal硬件钱包收款？',
  tutorial_desc_3: '将 KeyPal硬件钱包 与 TokenPocket 连接，然后选择"收款"。',
  tutorial_title_4: '如何使用 KeyPal硬件钱包发送交易？',
  tutorial_desc_4:
    '将 KeyPal硬件钱包 与 TokenPocket 连接，选择"发送"和"转账"。',
  tutorial_title_5: '交易时如何输入PIN码？',
  tutorial_desc_5: '',
};
