export default {
  text_1_1: 'Tutorial',

  text_2_1: 'Backup Tutorial',
  text_2_2: 'Import Tutorial',
  text_2_3: 'Advanced Tutorial (Payment Feature)',

  text_3_1: 'Create Wallet',
  text_3_1_1: "After selecting 'Create Wallet,' proceed to the backup process.",

  text_3_2: 'Backup',
  text_3_2_1:
    "On the backup page, select 'KeyPal Card Backup.' The entire backup process is divided into 4 steps: (1) Set a PIN code for your KeyPal Card; (2) Backup your recovery phrase to your KeyPal Card; (3) Verify the accuracy of your backup. (4) Start using.",
  text_3_2_2:
    "Click 'Start Backup.' You will be required to enter your PIN code twice to prevent any errors. The PIN code is used to ensure the security of your backup and to determine access to the card. Please note: You are responsible for keeping your PIN code secure, so be sure to remember it!",
  text_3_2_3:
    'After setting the PIN code, follow the instructions to place the KeyPal Card on the rear camera of your phone, which is the NFC chip sensing area. Please note that establishing a secure connection with KeyPal Card on iOS devices may take a few seconds, and there may be a brief delay. Rest assured, this is a normal occurrence.',
  text_3_2_4:
    'Once the sensing is complete, and the data is accurate, your recovery phrase will be encrypted and written into the KeyPal Card. After the backup is finished, the interface will remind you that the backup is complete.',
  text_3_2_5:
    'After the backup is complete, proceed to the final step, which is backup verification. We highly recommend performing backup verification!',

  text_3_3: 'Verify',
  text_3_3_1:
    'Following the verification instructions, you will need to enter the PIN code that you have previously set for your KeyPal Card. After entering the PIN code, follow the instructions to place the KeyPal Card near the rear camera for NFC sensing.',
  text_3_3_2:
    'If the verification is successful, you will see the following prompt. To prevent the leakage of the mnemonic phrase, it will be displayed partially obscured. You can follow the prompts to check if the mnemonic words match.',
  text_3_3_3:
    "After completing the verification, you can choose 'Next' to proceed directly to using it. To enhance the versatility and security of your KeyPal Card, we also offer the 'Payment Function.' You can visit the 'Advanced Features Tutorial' for more information.",

  text_3_4: 'Notes and Precautions',
  text_3_4_1:
    '1. TokenPocket does not store your PIN code. Please ensure that you remember and back up your PIN code yourself.',
  text_3_4_2:
    '2. The PIN code for each KeyPal Card can be unique. If you enter the PIN code incorrectly consecutively 10 times during usage, all data on your KeyPal Card will be completely erased!',
  text_3_4_3: '3. It is recommended to use a new KeyPal Card for backups.',
  text_3_4_4:
    '4. Resetting the KeyPal Card will result in the complete and irreversible deletion of all data. Please make sure you fully understand this!',
  text_3_4_5:
    '5. Your KeyPal Card may experience slower performance when used for backups or during usage on iOS. This is considered a normal occurrence, so please be patient.',

  text_4_1: 'Import Tutorial',
  text_4_2_1:
    'When you have a KeyPal Card with backups, you can import the wallets backed up on the KeyPal Card into TokenPocket for use. KeyPal Card import can be done through single-network import and multi-network import. Here, we will primarily explain the multi-network import method, which is similar to the single-network import method.',
  text_4_3_1:
    "1. After selecting 'Import Wallet,' click on 'KeyPal Hardware Wallet.' Then, in the pop-up window, choose KeyPal Card as your import method. As shown in the image below:",
  text_4_3_2:
    "2. After selecting KeyPal Card for import, you can click on 'Start Import' and enter the PIN code of your already backed up KeyPal Card.",
  text_4_3_3:
    "3. After entering the PIN code, the start scanning page will automatically appear. You can then place the KeyPal Card near the rear camera. When you're ready, click 'Start Scanning' to initiate the NFC sensing and enter the KeyPal Card reading process. Importing on iOS devices may take a bit of time, so please be patient.",
  text_4_3_4:
    "4. When the recovery phrase is successfully read, you will receive a notification. You can choose 'Next' to proceed to the next step, where you can select the wallet network and create your wallet. Afterward, you can start using the wallet. To enhance the versatility and security of your KeyPal Card, we also offer the 'Payment Function.' You can visit the 'Advanced Features Tutorial' for more information.",
  text_4_4: 'Notes and Precautions',
  text_4_4_1:
    '1. Your KeyPal Card may experience slower performance when used for backups or during usage on iOS. This is considered a normal occurrence, so please be patient.',
  text_4_4_2:
    '2. Please note: Before reading your KeyPal Card, you will be required to enter the PIN code. To ensure the security of your KeyPal Card, if the PIN code is entered incorrectly consecutively 10 times, the KeyPal Card will automatically erase all data. Once entered correctly, the error count will reset to zero.',
  text_4_4_3:
    "3. When using the 'Payment Function,' the recovery phrase will not be stored on your mobile device. Each time you need to make a transaction, you will need to scan your KeyPal Card for transaction signing.",

  text_5_1: 'Advanced Tutorial',
  text_5_2_1:
    'The payment function of KeyPal Card is designed to enhance the versatility of your KeyPal Card while keeping your mnemonic phrase and private key offline and more secure, no longer stored on your mobile device.',
  text_5_3_1:
    '1. When creating or importing, in the advanced options, you can choose not to store the recovery phrase or private key on your device. When you need to make transactions later, you will need to sign transactions by scanning the KeyPal Card.',
  text_5_3_2:
    "2. When you initiate a transfer or a DApp transaction, the bottom of the transaction panel will prompt you to 'Scan Card for Payment.' Clicking on it will take you to the card scanning payment process.",
  text_5_3_3:
    "3. After clicking 'Scan Card for Payment,' you will need to enter the PIN code of your KeyPal Card to verify your identity.",
  text_5_3_4:
    '4. After entering the PIN code, follow the instructions to place your KeyPal Card for card reading and transaction signing. Once the signature is complete, the transaction will be sent to the blockchain, completing the transaction.',
  text_5_4: 'Notes and Precautions',
  text_5_4_1:
    '1. Please note: Before reading your KeyPal Card, you will be required to enter the PIN code. To ensure the security of your KeyPal Card, if the PIN code is entered incorrectly consecutively 10 times, the KeyPal Card will automatically erase all data. Once entered correctly, the error count will reset to zero.',
  text_5_4_2:
    "2. When using the 'Payment Function,' the recovery phrase will not be stored on your mobile device. Each time you need to make a transaction, you will need to scan your KeyPal Card for transaction signing.",
};
