export default {
  shopNow: '現在購買',
  preSale: '預售中',
  header: {
    title: '安全易用的硬件錢包',
    subtitle: '您更安全、友好的存儲和管理數字資產',
  },
  introduce: {
    title: '你值得擁有一個更專業的硬件錢包',
    subtitle: '安全、可信賴、易用',
    desc_1: '堅硬的外殼，避免物理損傷',
    desc_2: '軍工級芯片，保障錢包安全',
    desc_3: '設備使用時，進行安全校驗',
    desc_4: '鑰離線生成，本地安全保存',
    desc_5: '大屏幕顯示，所見即所簽',
    desc_6: 'PIN碼保護，雙重安全',
  },
  function: {
    title: '一個全方位的數字資產硬件錢包',
    subtitle: '滿足您對數字資產的所有需求',
    func_title_1: '安全與存儲',
    func_desc_1:
      '內置頂級安全芯片，生成私鑰並儲存在KeyPal中，除了您，沒有人可以訪問它。支持1000多條公鏈。',
    func_title_2: '購買和增值',
    func_desc_2:
      '以簡單、安全和智能的方式購買BTC、ETH和其他數字資產，您可以在錢包內一站式資產增值。',
    func_title_3: '兌換與跨鏈',
    func_desc_3: '支持您快速且安全的進行數字資產兌換，輕鬆實現跨鏈。',
    func_title_4: '解鎖更多數字資產玩法',
    func_desc_4: '可安全訪問所有去中心化應用-DeFi、Dapp、GameFi和NFT等。',
  },
  software: {
    title: 'KeyPal 與 TokenPocket',
    connect: '連接方式',
    crossPlatform: '跨平台的',
    multiChains: '支持多鏈',
    supportDesc:
      '（支持所有EVM兼容鏈，如BNB智能鏈，Polygon，Fantom，Arbitrum等）',
    more: '更多',
  },
  specification: {
    title: 'KeyPal規格參數',
    card_1: '核心',
    option_label_1_1: '芯片',
    option_desc_1_1: 'MCU + SE',
    option_label_1_2: '證書及標準',
    option_desc_1_2: 'DOE, CEC, BEC, CE, FCC & RoHS',

    card_2: '電池及連接器',
    option_label_2_1: '電池',
    option_desc_2_1: '300 mAh',
    option_label_2_2: '連接器',
    option_desc_2_2: 'USB Type-C',

    card_3: '尺寸及重量',
    option_label_3_1: '尺寸',
    option_desc_3_1: '91 x 55 x 9.0(mm)',
    option_label_3_2: '重量',
    option_desc_3_2: '63(g)',

    card_4: '顯示',
    option_label_4_1: '大小',
    option_desc_4_1: '2英寸',
    option_label_4_2: '顯示屏',
    option_desc_4_2: '彩色LCD',
    option_label_4_3: '分辨率',
    option_desc_4_3: '320 x 480',

    card_5: '兼容性',
    option_desc_5_1: 'iOS, Android, macOS, Windows, Linux',

    card_6: '連接方式',
    option_desc_6_1: '藍牙及USB Type-C',

    card_7: '材料',
    option_desc_7_1: '鋁合金',

    card_8: '顏色',
    option_desc_8_1: '黑, 銀, 金',

    btnTitle: '準備好開始使用KeyPal錢包',
    btnSubTitle: '安全易用的硬件錢包',
  },
};
