<template>
  <div class="introduce content">
    <div class="title">{{ $t('home.introduce.title') }}</div>
    <div class="subtitle">{{ $t('home.introduce.subtitle') }}</div>
    <div class="introduce-list d-flex flex-wrap jc-between">
      <div
        class="introduce-item d-flex flex-column ai-center"
        v-for="(item, index) in introduceList"
        :key="index"
      >
        <img :src="item.img" />
        <span class="text">{{ item.desc }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    introduceList() {
      return [
        {
          img: require('../../assets/images/introduce/introduce-1.png'),
          desc: this.$t('home.introduce.desc_1'),
        },
        {
          img: require('../../assets/images/introduce/introduce-2.png'),
          desc: this.$t('home.introduce.desc_2'),
        },
        {
          img: require('../../assets/images/introduce/introduce-3.png'),
          desc: this.$t('home.introduce.desc_3'),
        },
        {
          img: require('../../assets/images/introduce/introduce-4.png'),
          desc: this.$t('home.introduce.desc_4'),
        },
        {
          img: require('../../assets/images/introduce/introduce-5.png'),
          desc: this.$t('home.introduce.desc_5'),
        },
        {
          img: require('../../assets/images/introduce/introduce-6.png'),
          desc: this.$t('home.introduce.desc_6'),
        },
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
.introduce {
  // margin-top: 1rem;
  .introduce-list {
    max-width: 7.78rem;
    margin: 0 auto;
    margin-top: 0.2rem;
    .introduce-item {
      &:nth-child(n + 4) {
        margin-top: 0.2rem;
      }
      width: 2rem;
      img {
        width: 1.84rem;
      }
      span {
        font-size: 0.12rem;
        font-family: Inter-Regular;
        text-align: center;
        color: #101010;
        line-height: 0.15rem;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .introduce {
    .introduce-list {
      margin-top: 0.15rem;
      justify-content: space-around;
      .introduce-item {
        width: 2.78rem;
        &:nth-child(n + 3) {
          margin-top: 0.3rem;
        }
        // img {
        //   width: 2.78rem;
        // }
        span {
          font-size: 0.24rem;
          line-height: 0.29rem;
        }
      }
    }
  }
}
</style>
