export default {
  buy_desc_1: '支持超过1000种公链',
  buy_desc_2: '包括 BTC、ETH、TRON, BSC和 EVM 兼容的公链',
  buy_desc_3: '一台KeyPal，管理所有数字资产',

  features: '产品特点',
  features_title_1: 'MCU+SE安全双芯品',
  features_desc_1: '军工级芯片，保障资产安全',
  features_title_2: '2英寸LCD全彩大屏幕',
  features_desc_2: '尽览交易细节，所见即所签',
  features_title_3: '采用蓝牙安全连接方式',
  features_desc_3: '采用脱敏通讯技术',
  features_title_4: '物理、加密多种保护',
  features_desc_4: '不触网, PIN码, 校验连接',
  color: '颜色',
  free_shipping: '免运费',

  break_down_desc_1: '向左按键',
  break_down_desc_2: '在显示屏中向左移动或返回上一页',
  break_down_desc_3: '确认按键',
  break_down_desc_4: '确认操作或进入菜单页面，长按3秒开机或关机',
  break_down_desc_5: '向上按键',
  break_down_desc_6: '在显示中向上移动',
  break_down_desc_7: '向右按键',
  break_down_desc_8: '在显示中向右移动或确认操作',
  break_down_desc_9: '向下按键',
  break_down_desc_10: '在显示中向下移动',

  display_title: '显示器',
  display_desc: '2英寸，LCD全彩屏幕',
  battery_title: '电池',
  battery_desc: '100毫安',
  materials_title: '材质',
  materials_desc: '铝合金',
  dual_chip_title: '双芯片',
  dual_chip_desc: 'MCU + SE 安全双芯片',

  easy_and_safe_title: '易用、安全',
  easy_and_safe_desc: '简单、安全、易使用，全方位保护您的数字资产安全',
  easy_and_safe_list_1: '双芯片',
  easy_and_safe_list_2: 'MCU + SE 安全双芯片',
  easy_and_safe_list_3: '简易连接',
  easy_and_safe_list_4: '蓝牙 和 Type-C',
  easy_and_safe_list_5: '2英寸全彩屏幕',
  easy_and_safe_list_6: '所见即所签',

  product_specifications: '产品规格',
  detail_center: 'USB Type-C',
  detail_left_1: '铝合金坚硬外壳，保护钱包不受损伤',
  detail_left_2: '向左按键',
  detail_left_3: '向下按键',
  detail_right_1: '2英寸大彩屏，尽览交易细节',
  detail_right_2: '向上按键',
  detail_right_3: '向右按键',
  detail_right_4: '确认按键',

  keypal_card_1: '尺寸',
  keypal_card_label_1: '91mm*55mm*6.7mm',
  keypal_card_2: '电池',
  keypal_card_label_2: '100毫安',
  keypal_card_3: '连接方式',
  keypal_card_label_3: '蓝牙和Type-C',
  keypal_card_4: '材质',
  keypal_card_label_4: '铝合金',
  keypal_card_5: '重量',
  keypal_card_label_5: '53g',
  keypal_card_6: '显示',
  keypal_card_label_6: '2英寸全彩大屏幕',
  keypal_card_7: '双芯片',
  keypal_card_label_7: 'MCU+SE安全双芯片',
  keypal_card_8: '证书',
  keypal_card_label_8: 'DOE CEC BEC CE FCC RoHS',

  package: '包装',
  package_line_1: '1 个硬件钱包',
  package_line_2: '1 条USB-Type-C 数据线',
  package_line_3: '2 张助记词卡',
  package_line_4: '1 张贴纸',

  keypal_plus: {
    larger_battery: '电池容量大2倍',

    keypal_card_1: '尺寸',
    keypal_card_label_1: '91mm*55mm*9.0mm',
    keypal_card_2: '电池',
    keypal_card_label_2: '300毫安',
    keypal_card_3: '连接方式',
    keypal_card_label_3: '蓝牙和Type-C',
    keypal_card_4: '材质',
    keypal_card_label_4: '铝合金',
    keypal_card_5: '重量',
    keypal_card_label_5: '63g',
    keypal_card_6: '显示',
    keypal_card_label_6: '2英寸全彩大屏幕',
    keypal_card_7: '双芯片',
    keypal_card_label_7: 'MCU+SE安全双芯片',
    keypal_card_8: '证书',
    keypal_card_label_8: 'DOE CEC BEC CE FCC RoHS',

    color: '颜色',
    color_desc: '磨砂黑, 磨砂银, 香槟金',
    battery: '电池',
    battery_desc: '300 毫安',
    left: '向左',
    down: '向下',
    right: '向右',
    up: '向上',
    ok: '确认',
    center: 'USB Type-C',
    left_1: '向左按键',
    left_2: '在显示屏中向左移动或返回上一页',
    left_3: '向下按键',
    left_4: '在显示中向下移动',
    right_1: '向上按键',
    right_2: '在显示中向上移动',
    right_3: '向右按键',
    right_4: '在显示中向右移动或确认操作',
    right_5: '确认按键',
    right_6: '确认操作或进入菜单页面，长按3秒开机或关机',
    mobile_center: 'Type-C',
    mobile_center_2: '用于充电和固件升级',
  },
};
