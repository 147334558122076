export default {
  title_1: '为什么您需要一个硬件钱包？',
  desc_1_1: '由于Web3是建立在智能合约之上，给部分区块链用户带来了诸多风险',
  desc_1_2:
    '当您需要创建钱包或签署任何交易时，您使用的手机和电脑存在被入侵、病毒植入、粘贴板被监控等未知风险。 此时，您需要一款安全的设备在Web3世界畅游。',
  desc_1_3: '因此，硬件钱包是您最佳的一个选择。',

  title_2: '为什么硬件钱包安全？',
  desc_2_1: '搭载安全芯片',
  desc_2_2: '帮助您安全创建钱包',
  desc_2_3: '安全储存信息',
  desc_2_4: '离线存储和管理私钥',
  desc_2_5: '避免未知风险入侵',
  desc_2_6: '远离诈骗',

  title_3: '为什么是KeyPal硬件钱包？',
  desc_3_1: '它搭载双芯片',
  desc_3_2: 'KeyPal硬件钱包搭载MCU和SE安全双芯片，具有防篡改和安全保护功能。',
  desc_3_3: '防伪认证',
  desc_3_4:
    ' 每台KeyPal硬件钱包设备在生产过程中都有唯一的身份证明，设备背面激光雕刻有唯一的序列号。 身份证书用于设备在线防伪验证',
  desc_3_5: '隐私数据加密传输',
  desc_3_6:
    'KeyPal硬件钱包通过蓝牙和USB双通道与配套应用进行通信。在蓝牙通信过程中，链路层不会传输任何敏感隐私数据，杜绝通过蓝牙通信截获任何敏感数据。',
  desc_3_7: 'PIN码保护',
  desc_3_8:
    'KeyPal硬件钱包需在创建或恢复钱包时设置开机PIN码，并在每一次开机时正确输入PIN码才可继续使用。如PIN码连续输入错误，硬件钱包将自动重置为出厂设置，清空其中保存的所有钱包数据，以此保证硬件钱包在他人误拿或丢失、被盗等情况下不被他人查看和使用。 ',
  desc_3_9:
    'KeyPal硬件钱包遵循BIP32/BIP39/BIP44协议，为助记词的生成、管理和保护提供离线、离网的安全环境。 通过蓝牙加密通信、PIN码保护和2.0全彩大屏显示，为用户显示每一笔区块链交易签名，即让用户“所见即所签”，保护所有交易数据，防止被篡改，让一切攻击无所遁形。',

  SE_desc_1: '1. 内置真随机数发生器',
  SE_desc_2: '2. 多重安全存储',
  SE_desc_3: '3. 确保私钥不脱离芯片',
  SE_desc_4: '4. 抗暴力破解，防止外界读取信息',
  MCU_desc_1: '1. 银行金融级加密',
  MCU_desc_2: '2. 系统安全和篡改检测',
  MCU_desc_3: '3. 抵御外部攻击和代码注入',
};
