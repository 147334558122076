export default {
  title: 'KeyPal Manager',
  desc: 'KeyPal固件升級工具',
  download: '下載 KeyPal Bridge 客戶端',
  onLine: '在線升級',
  goDesktop: '請前往電腦端操作',
  macTitle: 'Mac(蘋果自研發芯片)',
  question_1: '如何通過客戶端升級KeyPal？',
  question_2: '如何在線進行升級？',
}
