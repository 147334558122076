/** 设置显示模式 */
export function setMode(mode) {
  if (mode) {
    document.documentElement.setAttribute('theme', 'dark');
  } else {
    document.documentElement.removeAttribute('theme');
  }
}
// 获取当前模式
// export function getMode() {
//   if (document.documentElement.hasAttribute('theme')) {
//     return false
//   } else {
//     return true
//   }
// }

export function createVideo(url) {
  const video = document.createElement('video');

  video.src = url;
  video.muted = true;
  video.playbackRate = 1;
  video.currentTime = 0;
  video.setAttribute('muted', '');
  video.setAttribute('playsinline', '');
  video.setAttribute('type', 'video/webm');
  video.setAttribute('preload', 'none');
  video.classList.add('video');
  video.style.display = 'none';
  window.document.body.appendChild(video);

  return video;
}

export function createFrame(video, videoWidth, videoHeight) {
  const canvas = window.OffscreenCanvas
    ? new OffscreenCanvas(videoWidth, videoHeight)
    : document.createElement('canvas');
  const context = canvas.getContext('2d');

  canvas.width = videoWidth;
  canvas.height = videoHeight;
  context.drawImage(video, 0, 0, videoWidth, videoHeight);

  return canvas;
}

export function cacheFrame(videoMetaData,fps) {
  return new Promise((resolve, reject) => {
    const { url, frameCount } = videoMetaData;
    const video = createVideo(url);
    const framesStore = new Array(frameCount).fill(false);
    let videoWidth = 0;
    let videoHeight = 0;
    let setIn = 0;
    let framesNumber = 0;

    video.play();
    video.addEventListener('loadedmetadata', (res) => {
      videoWidth = video.videoWidth;
      videoHeight = video.videoHeight;
    });

    video.addEventListener('ended', () => {
      resolve(framesStore);
    });

    video.addEventListener('waiting', (res) => {
      clearInterval(setIn);
    });

    video.addEventListener('error', () => {
      reject([]);
    });

    video.addEventListener('canplaythrough', (res) => {
      clearInterval(setIn);

      setIn = setInterval(() => {
        if (framesNumber >= frameCount) clearInterval(setIn);

        framesStore[framesNumber] = createFrame(video, videoWidth, videoHeight);
        framesNumber++;
      }, fps);
    });
  });
}

export function renderFrame(ctx, frame) {
  ctx.clearRect(0, 0, 1920, 1080);
  ctx.drawImage(frame, 0, 0);
}

export function showLoading() {
  const div = document.createElement('div');
  div.classList.add('loading');
  div.innerText = 'file loading ...';
  document.body.appendChild(div);
}

export function hideLoading() {
  document.querySelector('.loading').remove();
}
