export default {
  text_1_1: '教程',

  text_2_1: '备份教程',
  text_2_2: '导入教程',
  text_2_3: '高级教程(支付功能)',

  text_3_1: '创建钱包',
  text_3_1_1: '1. 选择创建钱包后，进入备份流程',

  text_3_2: '备份流程',
  text_3_2_1:
    '备份的页面，选择KeyPal Card备份。整个备份流程分为3步，（1）为您的KeyPal Card设置PIN码； （2）将助记词备份到您的KeyPal Card中；（3）验证您的备份是否正确 （4）开始使用',
  text_3_2_2:
    '点击开始备份，需要您输入2次PIN码，以避免您错误输入PIN码，PIN用于保护备份安全，且用于卡片访问权限判断。请注意：PIN码由您自己保管，请务必记得！',
  text_3_2_3:
    '设置好PIN码后，按照指引将KeyPal Card放置在手机后置摄像处，此处为NFC芯片感应区域。由于iOS设备在与KeyPal Card建立安全链接会消耗一点时间，因此可能出现数秒无反应，请放心，该现象是正常情况。',
  text_3_2_4:
    '感应完成并数据无误后，则会将您的助记词加密写入到KeyPal Card中。备份完成后，界面会提醒您备份完成。',
  text_3_2_5:
    '备份完成后，进入最后一步，备份验证，我们十分建议您进行备份验证！',

  text_3_3: '验证流程',
  text_3_3_1:
    '根据验证指引，您需先输入已设置好的KeyPal Card的PIN码，输入完成后，按照指引，将KeyPal Card放置在后置摄像头附近，进行NFC感应',
  text_3_3_2:
    '验证成功，则会进入以下提示。为了避免助记词泄露，因此会进行模糊化，您可以根据提示，点击查看助记词是否都一致。',
  text_3_3_3:
    '结束验证后，您可以选择”下一步“，直接进入使用；为了增加您使用KeyPal Card的场景以及安全性，我们也提供了KeyPal Card的 ”支付功能“，您可以前往”高级功能教程“查看。',

  text_3_4: '注意事项',
  text_3_4_1: '1. TokenPocket不会存储您的PIN码，请您务必自己做好记忆和备份',
  text_3_4_2:
    '2. 每个KeyPal Card的PIN码都可以不一样，您在使用过程中，连续错误输入10次PIN码后，您的KeyPal Card数据会被完全清楚！',
  text_3_4_3: '3. 使用KeyPal Card进行备份都建议您使用新Card进行备份',
  text_3_4_4:
    '4. KeyPal Card进行重置会导致数据被全部清除且无法恢复，请您务必了解该内容！',
  text_3_4_5:
    '5. 您的KeyPal Card在使用iOS进行备份、使用过程中，可能出现较慢的情况，该情况属于正常现象，请您耐心等待',

  text_4_1: '导入教程',
  text_4_2_1:
    '当您有已备份的KeyPal Card，您可以将KeyPal Card中备份的钱包导入到TokenPocket中进行使用。KeyPal Card导入可以分为单网咯导入和多网络方式导入。这里我们主要讲解多网络导入方式，单网络导入的方式相似。',
  text_4_3_1:
    '1. 您选择导入钱包后，点击KeyPal硬件钱包，其后在弹窗中选择KeyPal Card作为您的导入方式。如图',
  text_4_3_2:
    '2. 选择KeyPal Card导入之后，您即可点击开始导入，输入您已有备份的KeyPal Card的PIN码。',
  text_4_3_3:
    '3. 输入完PIN码后，会自动弹出开始扫描页面，随后您可以将KeyPal Card放置在后置摄像头附近，您准备好后，即可点击“开始扫描”，进行NFC感应，进入KeyPal Card读取流程。iOS设备的导入可能需要一点时间，中间请等待一下。',
  text_4_3_4:
    '4. 当助记词读取成功后，会对您进行提醒，您可选择“下一步”进入下一个流程，进行钱包网络选择与创建，随后即可开始使用钱包。为了增加您使用KeyPal Card的场景以及安全性，我们也提供了KeyPal Card的 ”支付功能“，您可以前往”高级功能教程“查看。',
  text_4_4: '注意事项',
  text_4_4_1:
    '1. 您的KeyPal Card在使用iOS进行备份、使用过程中，可能出现较慢的情况，该情况属于正常现象，请您耐心等待。',
  text_4_4_2:
    '2. 请您注意：在读取您的KeyPal Card过程前，会要求您输入PIN码，为了确保您的KeyPal Card安全，PIN码一旦连续错误10次，KeyPal Card将自动清除所有数据。一旦正确，错误次数则清零。',
  text_4_4_3:
    '3. 使用“支付功能”，助记词将不会在您的手机设备中保存，您每次需要进行交易时，都需要进行扫描您的KeyPal Card进行交易签名。',

  text_5_1: '高级功能',
  text_5_2_1:
    'KeyPal Card的支付功能旨在增强您的KeyPal Card使用场景，同时让您的助记词、私钥不再保存在您的手机设备中，让其保持离线且更安全。',
  text_5_3_1:
    '1. 创建或导入的时候，高级选项中，您可以选择不将助记词或私钥保留在您的设备中；后续您需要进行交易的时候，则需要通过扫描KeyPal Card的方式进行交易签名',
  text_5_3_2:
    '2. 当您发起转账或者一笔DApp交易时，交易面板的底部会提示您”扫卡支付“，点击之后进入扫卡支付流程',
  text_5_3_3:
    '3. 点击”扫卡支付“后，您需要输入KeyPal Card的PIN码；以验证您的身份。',
  text_5_3_4:
    '4. 输入PIN码之后，按照指引，放置您的KeyPal Card，进行卡片读取并进行交易签名，签名结束后会将交易发送到区块链上，完成交易。',
  text_5_4: '注意事项',
  text_5_4_1: '1. 请您注意：在读取您的KeyPal Card过程前，会要求您输入PIN码，为了确保您的KeyPal Card安全，PIN码一旦连续错误10次，KeyPal Card将自动清除所有数据。一旦正确，错误次数则清零。',
  text_5_4_2: '2. 使用“支付功能”，助记词将不会在您的手机设备中保存，您每次需要进行交易时，都需要进行扫描您的KeyPal Card进行交易签名。',
};
