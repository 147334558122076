<template>
  <div class="function content">
    <div class="title">{{ $t('home.function.title') }}</div>
    <div class="subtitle">{{ $t('home.function.subtitle') }}</div>
    <div class="function-wrap d-flex ai-center jc-between">
      <div class="function-list">
        <div
          class="function-item"
          :class="{
            'function-item-1':
              (funcIndex === 0 && index === 0) ||
              (funcIndex === 3 && index === 3),
            'function-item-2':
              (funcIndex === 1 && index === 1) ||
              (funcIndex === 2 && index === 2),
            selected: funcIndex === index,
          }"
          v-for="(item, index) in functionList"
          :key="index"
          @click="onOpen(item.url)"
          @mouseenter="onMouseEnter(index)"
          @mouseleave="onMouseLeave(index)"
        >
          <div class="title-row d-flex ai-center">
            <div class="d-flex ai-center">
              <div class="triangle"></div>
              <div class="function-title text">{{ item.title }}</div>
            </div>
            <div
              v-if="item.transit"
              class="transit-btn d-flex ai-center jc-center"
              :class="{ pointerEvents: canClick }"
              @click.stop="onTransit(item.url)"
            >
              <img src="../../assets/images/home/transit.png" />
              <span class="text">{{ item.transit }}</span>
            </div>
            <img
              class="add-btn"
              v-show="funcIndex !== index"
              src="../../assets/images/home/add.png"
            />
          </div>
          <div class="function-desc text">
            {{ item.desc }}
          </div>
          <div
            class="function-desc function-desc-mobile text"
            v-show="funcIndex === index"
          >
            {{ item.desc }}
          </div>
        </div>
        <div class="button d-flex ai-center jc-center" @click="goBuyKeyPal">
          <div class="img-wrap"></div>
          <span class="text">{{ $t('home.shopNow') }}</span>
        </div>
      </div>
      <div class="detail-img">
        <span class="mobile-wrap">
          <img class="shell" src="../../assets/images/home/func-mobile.png" />
          <div class="filter"></div>
          <img class="detail detail-bg" :src="lastDetailImg" />
          <div class="grey" v-if="isAnimation"></div>
          <div class="detail mask"></div>
          <img ref="detailAni" class="detail detail-ani" :src="detailImg" />
        </span>
        <img class="keypal" :src="keypalImg" />
      </div>
    </div>
    <div class="bg-container">
      <img class="bg-1" src="../../assets/images/home/func-bg-1.png" />
      <img class="bg-2" src="../../assets/images/home/func-bg-2.png" />
      <img class="bg-3" src="../../assets/images/home/func-bg-3.png" />
    </div>
  </div>
</template>

<script>
import mobile from '../../mixins/mobile';
export default {
  mixins: [mobile],
  data() {
    return {
      funcIndex: 0,
      lastFuncIndex: 0,
      isAnimation: false,
      canClick: false,
    };
  },
  computed: {
    functionList() {
      return [
        {
          title: this.$t('home.function.func_title_1'),
          desc: this.$t('home.function.func_desc_1'),
        },
        {
          title: this.$t('home.function.func_title_2'),
          desc: this.$t('home.function.func_desc_2'),
          url: 'https://buy.transit.finance/',
          transit: 'Transit Buy',
        },
        {
          title: this.$t('home.function.func_title_3'),
          desc: this.$t('home.function.func_desc_3'),
          url:
            this.$i18n.locale === 'en'
              ? 'https://swap.transit.finance/?locale=en#/'
              : 'https://swap.transit.finance/?locale=zh#/',
          transit: 'Transit Swap',
        },
        {
          title: this.$t('home.function.func_title_4'),
          desc: this.$t('home.function.func_desc_4'),
        },
      ];
    },
    keypalImg() {
      return require(`../../assets/images/home/func-keypal-${this.funcIndex}.png`);
    },
    detailImg() {
      return require(`../../assets/images/home/func-detail-${this.funcIndex}.png`);
    },
    lastDetailImg() {
      return require(`../../assets/images/home/func-detail-${this.lastFuncIndex}.png`);
    },
  },

  methods: {
    goBuyKeyPal() {
      if (this.$i18n.locale === 'zh') {
        window.open('https://shop95838799.m.youzan.com/v2/showcase/homepage?alias=sptfzUCg0j')
      } else {
        window.open('https://www.keypal.store/products/keypal-hardware-wallet-plus')
      }
    },
    onTransit(url) {
      window.open(url);
    },
    onOpen(url) {
      if (!this.isMobile && url) {
        window.open(url);
      }
    },
    onMouseEnter(index) {
      this.canClick = true;
      // 设置一下延迟，解决点击的时候会触发 transition 跳转按钮的事件
      setTimeout(() => {
        this.canClick = false;
      }, 10);
      this.isAnimation = false;
      this.funcIndex = index;
      // console.log('detailAni', this.$refs.detailAni.classList)
      const detailAni = this.$refs.detailAni;
      if (detailAni) {
        detailAni.classList.remove('animation');
        if (this.lastFuncIndex !== index) {
          setTimeout(() => {
            detailAni.classList.add('animation');
            this.isAnimation = true;
          }, 10);
        }
      }
    },
    onMouseLeave(index) {
      // console.log('leave', index)
      this.lastFuncIndex = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.function {
  position: relative;
  margin-top: 2.16rem;
  .function-wrap {
    margin-top: 0.65rem;
    .function-list {
      .function-item {
        display: block;
        position: relative;
        margin-left: 0.06rem;
        max-width: 6rem;
        &:nth-child(n + 2) {
          margin-top: 0.4rem;
        }
        .title-row {
          position: relative;
        }
        .triangle {
          visibility: hidden;
        }
        .transit-btn {
          margin-left: 0.06rem;
          visibility: hidden;
          padding: 0.03rem 0.2rem 0.03rem 0.03rem;
          background: #2980fe;
          border-radius: 0.14rem;
          pointer-events: auto;
          &.pointerEvents {
            pointer-events: none;
          }
          &.buy {
            background: #202020;
          }
          img {
            width: 0.22rem;
            border-radius: 50%;
          }
          span {
            margin-left: 0.06rem;
            font-size: 0.12rem;
            color: #ffffff;
          }
        }
        .add-btn {
          display: none;
        }
        cursor: pointer;
        .function-title {
          margin-left: 0.06rem;
          font-size: 0.18rem;
          font-family: 'Bold';
          color: #101010;
          line-height: 0.21rem;
        }
        .function-desc {
          margin-top: 0.08rem;
          margin: 0.08rem 0 0 0.14rem;
          font-size: 0.12rem;
          font-family: Inter-Regular;
          color: #555555;
          line-height: 0.15rem;
        }
        .function-desc-mobile {
          display: none;
        }
      }
      .function-item-1 {
        .triangle {
          visibility: visible;
        }
        .function-title {
          color: #2980fe;
        }
        .function-desc {
          color: #77afff;
        }
      }
      .function-item-2 {
        .triangle {
          visibility: visible;
        }
        .transit-btn {
          visibility: visible;
        }
        .function-title {
          color: #2980fe;
        }
        .function-desc {
          color: #77afff;
        }
      }
      .button {
        width: fit-content;
        margin-top: 0.34rem;
        padding: 0.1rem 0.76rem;
        background: #202020;
        border-radius: 0.4rem;
        cursor: pointer;
        .img-wrap {
          width: 0.26rem;
          height: 0.26rem;
          background: url('../../assets/images/home/shop-card-1.png') no-repeat;
          background-size: 100% 100%;
        }
        span {
          margin-left: 0.1rem;
          font-size: 0.16rem;
          font-family: Inter-Regular;
          color: #ffffff;
        }
      }
      .button:hover {
        background: #2980fe;
        .img-wrap {
          background: url('../../assets/images/home/shop-card-2.png') no-repeat;
          background-size: 100% 100%;
        }
      }
    }
    .detail-img {
      font-size: 0;
      @keyframes detailAni {
        0% {
          opacity: 0;
          transform: translate(-3.2rem, 0);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, 0);
        }
      }
      .mobile-wrap {
        display: inline-block;
        position: relative;
        width: 2.2rem;
        height: 4.56rem;
        .shell {
          width: 2.2rem;
        }
        .filter {
          position: absolute;
          z-index: 0;
          top: 1.13rem;
          right: -0.2rem;
          width: 0.6rem;
          height: 3.16rem;
          opacity: 0.25;
          background: #666666;
          filter: blur(0.03rem);
        }
        .detail {
          width: 2.04rem;
          position: absolute;
          left: 50%;
          bottom: 0.06rem;
          z-index: -1;
          // transform: translate(-50%, 4.56rem);
          transform: translate(-3.14rem, 0);
          &.animation {
            animation: detailAni 0.5s forwards;
          }
        }
        .mask {
          height: 4.42rem;
          background: #fff;
          z-index: 1;
        }
        .detail-ani {
          opacity: 0;
        }
        .detail-bg {
          z-index: -2;
          transform: translate(-50%);
        }
        .grey {
          position: absolute;
          border-radius: 0.16rem;
          z-index: -2;
          left: 50%;
          top: 0.1rem;
          transform: translateX(-50%);
          width: 2rem;
          height: 4.36rem;
          background: rgba(0, 0, 0, 0.1);
        }
      }
      .keypal {
        width: 1.92rem;
        // height: max-content;
        transform: translate(-0.25rem, -0.25rem);
      }
    }
  }
  .bg-container {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    pointer-events: none;
    img {
      position: absolute;
    }
    .bg-1 {
      max-width: 2.09rem;
      top: -1.8rem;
      right: 1.5rem;
    }
    .bg-2 {
      max-width: 1.17rem;
      top: 0;
      left: 2rem;
    }
    .bg-3 {
      max-width: 0.74rem;
      top: 4rem;
      left: 6rem;
    }
    .bg-4 {
      max-width: 1.16rem;
      left: 0.2rem;
      bottom: -1.5rem;
    }
  }
}
// @media screen and (max-width: 1200px) {
//   .function {
//     .function-wrap {
//       // flex-direction: column;
//     }
//   }
// }
@media screen and (max-width: 767px) {
  .function {
    .function-wrap {
      margin-top: -0.5rem;
      flex-direction: column;
      .function-list {
        padding-top: 0.5rem;
        order: 2;
        background: #fff;
        .function-item {
          max-width: none;
          border-radius: 0.12rem;
          padding: 0.2rem 0.2rem 0.2rem 0.12rem;
          &.selected {
            background: #fafafa;
          }
          .function-title {
            font-size: 0.26rem;
          }
          .transit-btn {
            margin-left: 0.24rem;
            border-radius: 0.22rem;
            padding: 0.04rem 0.26rem 0.04rem 0.04rem;
            img {
              width: 0.36rem;
            }
          }
          .add-btn {
            display: block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            width: 0.24rem;
          }
          .function-desc {
            display: none;
            font-size: 0.24rem;
            line-height: 0.29rem;
            margin: 0.1rem 0 0 0.14rem;
          }
          .function-desc-mobile {
            display: block;
          }
        }
        .button {
          margin: 0 auto;
          margin-top: 0.36rem;
          height: 0.88rem;
          background: #2980fe;
          border-radius: 0.72rem;
          .img-wrap {
            width: 0.44rem;
            height: 0.44rem;
            background: url('../../assets/images/home/shop-card-2.png')
              no-repeat;
            background-size: 100% 100%;
          }
          span {
            margin-left: 0.12rem;
            font-size: 0.28rem;
          }
        }
        .button:hover {
          .img-wrap {
            background: url('../../assets/images/home/shop-card-2.png')
              no-repeat;
            background-size: 100% 100%;
          }
        }
      }
      .detail-img {
        display: flex;
        align-items: center;
        position: relative;
        z-index: -1;
        transform: translateY(1.7rem);
        order: 1;
        .mobile-wrap {
          width: 3.2rem;
          height: 6.63rem;
          .shell {
            width: 3.2rem;
            height: 6.63rem;
          }
          .detail {
            width: 2.98rem;
            height: 6.43rem;
            transform: translate(-4.6rem, 0);
          }
          .detail-bg {
            z-index: -2;
            transform: translate(-50%);
          }
          .grey {
            width: 3.2rem;
            height: 6.4rem;
            border-radius: 0.4rem;
          }
          .filter {
            display: none;
          }
        }
        .keypal {
          margin-left: 0.24rem;
          transform: translateY(-0.4rem);
        }
      }
    }
  }
}
</style>
