export default {
  title: 'KeyPal Manager',
  desc: 'Your KeyPal firmware upgrade tool',
  download: 'Download KeyPal Bridge',
  onLine: 'Online upgrade',
  goDesktop: 'Please go to the computer to operate',
  macTitle: 'Mac(Apple Silicon)',
  question_1: 'How to upgrade KeyPal through the desktop app?',
  question_2: 'How to upgrade for web?',
}
