export default {
  title_1: 'Why you need a hardware wallet?',
  desc_1_1:
    'Since the Web3 was built on smart contract, it brings many risks to some of the blockchain users.',
  desc_1_2:
    'When you need to create a wallet or sign any transactions, the mobile phone and computer you used have unknown risks such as being invaded, virus implanted, and the pasteboard being monitored. Then you look forward to using a safety device for surfing on the Web3.',
  desc_1_3: 'At this point, a hardware wallet is one of your best options',

  title_2: 'Why hardware wallet is secure?',
  desc_2_1: 'Security chips',
  desc_2_2: 'Help you to create a wallet',
  desc_2_3: 'Secure storage',
  desc_2_4: 'Offline storage and manage keys',
  desc_2_5: 'Avoiding hacking',
  desc_2_6: 'Keep away from scams',

  title_3: 'Why KeyPal?',
  desc_3_1: 'Dual-chip',
  desc_3_2:
    'KeyPal hardware wallet features with dual-chip security protection of anti-tampering MCU+SE security chip',
  desc_3_3: 'Anti-counterfeiting certification',
  desc_3_4:
    'Each KeyPal hardware wallet device has a unique identity certificate during the production process, and a unique serial number is laser engraved on the back of the device. The identity certificate is used for online anti-counterfeiting verification of the device.',
  desc_3_5: 'Prevent any privacy data to transmit',
  desc_3_6:
    'The KeyPal hardware wallet communicates with the companion app via dual-channel Bluetooth and USB. In the process of Bluetooth communication, the link layer will not transmit any sensitive privacy data, preventing any sensitive data from being intercepted through Bluetooth communication.',
  desc_3_7: 'PIN code',
  desc_3_8:
    'The KeyPal hardware wallet needs to set a power-on PIN code when creating or restoring the wallet, and enter the PIN code correctly every time it is powered on before it can continue to be used. If the PIN code is continuously entered incorrectly, the hardware wallet will be automatically reset to the factory settings, and all wallet data stored in it will be cleared, so as to ensure that the hardware wallet will not be viewed and used by others in the event of mistaking, loss, or theft.',
  desc_3_9:
    'The KeyPal hardware wallet follows the BIP32/BIP39/BIP44 protocol, providing an offline secure environment for generating, managing and protecting mnemonic phrases. Through Bluetooth encrypted communication, PIN code protection and 2.0-inch full-color display, each transaction signed can be displayed, which allow users to get “YOU SIGN WHAT YOU SEE", and make all transaction data tampering and other man-in-the-middle attacks not invisible.',

  SE_desc_1: '1. Built-in true random number generator',
  SE_desc_2: '2. Multiple secure storage',
  SE_desc_3: '3. Ensure that the seed does not leave the chip',
  SE_desc_4: '4. Resist brute-force attack and prevent external reading',
  MCU_desc_1: '1. Bank financial level encryption',
  MCU_desc_2: '2. System security and tamper detection',
  MCU_desc_3: '3. Resist external attack and code injection',
};
