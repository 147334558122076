const buyLink = {
  computed: {
    linkUrl() {
      if (this.isKeyPalPlus) {
        if (this.$i18n.locale === 'zh') {
          return 'https://shop95838799.m.youzan.com/wscgoods/detail/2797rrhqu8szbig';
        } else {
          return 'https://www.keypal.store/products/keypal-hardware-wallet-plus';
        }
      } else if (this.isHome) {
        if (this.$i18n.locale === 'zh') {
          return 'https://shop95838799.m.youzan.com/v2/showcase/homepage?alias=sptfzUCg0j';
        } else {
          return 'https://www.keypal.store/products/keypal-hardware-wallet-plus';
        }
      } else {
        if (this.$i18n.locale === 'zh') {
          return 'https://shop95838799.m.youzan.com/v2/showcase/homepage?alias=sptfzUCg0j';
        } else {
          // return 'https://keypalwallet.mystrikingly.com/store/products/keypal-hardware-wallet';
          return 'https://www.keypal.store/products/keypal-hardware-wallet-plus';
        }
      }
    },
  },
  methods: {
    goBuyKeyPal() {
      window.open(this.linkUrl);
    },
  },
};

export default buyLink;
