export default {
  text_1_1: 'Backing up has never been this simple and secure.',
  text_1_2: 'Enhanced options to meet your privacy needs.',
  text_2_1: 'Durable, portable, and resistant to bending.',
  text_2_2: 'Secured by CC EAL6+',
  text_2_3: 'ChipCarry it with you wherever you go.',
  text_2_4: 'Bend-resistant, magnet-proof, and static-proof.',
  text_2_5: 'Supports up to 500,000 card swipes.',
  text_3_1:
    'Empowered by TokenPocket, you can enjoy the perfect user experience while you are using the KeyPal Card.',
  text_4_1: 'PIN Code supported to enhance the security',
  text_4_2:
    'The card will be automatically erased after ten consecutive incorrect PIN attempts.',
  text_4_3: 'Not only for backup,but also for signing',
  text_4_4:
    "The card's versatility extends beyond backup purposes, as it also serves as an integral component for transaction signing.",
  text_4_5: 'NFC, one-touch backup, simple and secure.',
  text_4_6: 'Enable swift bidirectional encryption via electromagnetic fields for rapid and uncomplicated backup.',
  text_5_1: 'Time to get started with your',
  text_6_1: 'Wallet',
  text_6_2:
    'Seamlessly integrate for an unparalleled wallet experience where every KeyPal Card connects!',
  text_6_3: 'Backup',
  text_6_4:
    'Make your wallet backup effortless and secure, eliminating the risk of asset loss due to erroneous backups.',
  text_6_5: 'Signing',
  text_6_6:
    'Unlock diverse wallet usage with your KeyPal Card, enabling you to execute every signature with ease.',
};
